import { Button, Card, Col, Image, notification, Row, Space, Spin, Typography } from "antd";
import PageDescription from "App/Components/PageDescription";
import { FLAGS } from "Constants/Images";
import { FaFileDownload } from 'react-icons/fa';
import moment from "moment";
import { useApiRequest, } from "Hooks/API";
import { useEffect } from "react";
import { accountingFormat } from "Utils";
import { useNavigate, useParams } from "react-router-dom";
import URLS from "Routes/constants";
import { useExtendedApiRequest } from "Hooks/ExtendedAPI";
import { FORMATS } from "Constants/Formats";
import { useLoginStore } from "App/Pages/Login/store";
import { useSpecificManualTradeHistory } from "./query/useSpecificManualTradeHistory";
import { useSpecificManualTradeHistoryState } from "./store";

function SpecificManualTradeHistory() {
    const setState = useSpecificManualTradeHistoryState((state) => state.setState);
    const activateLoading = useSpecificManualTradeHistoryState((state) => state.activateLoading);
    const volopaAccountDetails = useSpecificManualTradeHistoryState((state) => state.volopaAccountDetails);
    const fundingLimitReached = useSpecificManualTradeHistoryState((state) => state.fundingLimitReached);
    const showPaymentsOver = useSpecificManualTradeHistoryState((state) => state.showPaymentsOver);
    const showPaymentsUnder = useSpecificManualTradeHistoryState((state) => state.showPaymentsUnder);
    const loading = useSpecificManualTradeHistoryState((state) => state.loading);
    const fundingDetails = useSpecificManualTradeHistoryState((state) => state.fundingDetails);
    const { mapId } = useParams();
    const { data, isFetching } = useSpecificManualTradeHistory(mapId);
    const config = useLoginStore(state => state.config);
    const apiRequest = useApiRequest();
    const navigate = useNavigate();
    const extendedApiRequest = useExtendedApiRequest();
    let curSend = data?.currency_sell?.currency;
    let batchId = data?.part_of_batch ? data?.tcc_quote_bb_self_id : '-';

    useEffect(() => {
        if (config?.manual_payment_status !== 1) {
            navigate(URLS.PaymentHistory)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config]);

    const fetchFundingDetails = async () => {
        if (curSend) {
            setState({ loading: true })
            let response = await apiRequest("tcc/fund/account/payment?currency=" + curSend);
            if (response && response.data && (response.data.result.funding_accounts?.find(account => account?.payment_type === "regular") || response.data.result.settlement_accounts[0])) {
                setState({
                    volopaAccountDetails: response.data.result,
                    loading: false
                });
                if (response.data.result.settlement_accounts[0]) {
                    updateFundingDetails(null, true, response.data.result);
                }
                if (response.data.result.funding_accounts?.find(account => account?.payment_type === "regular")) {
                    updateFundingDetails(null, false, response.data.result);
                }
                if (response.data.result.funding_accounts?.find(account => account?.payment_type === "regular") && response.data.result.settlement_accounts[0]) {
                    setState({
                        showPaymentsUnder: true,
                        showPaymentsOver: true,
                        fundingLimitReached: false
                    });
                } else {
                    if (response.data.result.settlement_accounts[0]) {
                        setState({
                            showPaymentsOver: true,
                            showPaymentsUnder: false
                        });
                    }
                    if (response.data.result.funding_accounts?.find(account => account?.payment_type === "regular")) {
                        setState({
                            showPaymentsUnder: true,
                            showPaymentsOver: false
                        });
                    }
                }
            }
        }
    }


    const updateFundingDetails = (e, over, details) => {
        let sortCodeLabel = 'Sort Code';
        let volopaAccountDetailsData = details ?? volopaAccountDetails;
        if (over === false) {
            let routingCodeType = volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code_type;
            if (routingCodeType === 'bic_swift') {
                sortCodeLabel = 'SWIFT/BIC'
            }
            if (routingCodeType === 'ach_routing_number') {
                sortCodeLabel = 'Ach Routing Number'
            }
            if (routingCodeType === 'wire_routing_number') {
                sortCodeLabel = 'Wire Routing Number'
            }
            setState({
                fundingDetails: {
                    ...volopaAccountDetails,
                    volopaAccountHolderName: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_holder_name,
                    volopaAccountSortCode: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code,
                    volopaAccountNumber: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_number,
                    volopaAccountCountry: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_country,
                    volopaAccountAddress: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_address,
                    volopaAccountName: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_name,
                    volopaAccountPaymentRef: volopaAccountDetailsData?.short_reference,
                    volopaAccountIban: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.iban,
                    volopaAccountSwift: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.bic_swift,
                    volopaAccountNumberType: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_number_type,
                    volopaAccountSortCodeLabel: sortCodeLabel,
                    short_reference: volopaAccountDetailsData?.short_reference
                },
                fundingLimitReached: false

            });
        } else {
            let routingCodeType = volopaAccountDetailsData?.settlement_accounts[0]?.routing_code_type_1;
            if (routingCodeType === 'bic_swift') {
                sortCodeLabel = 'SWIFT/BIC'
            }
            if (routingCodeType === 'ach_routing_number') {
                sortCodeLabel = 'Ach Routing Number'
            }
            if (routingCodeType === 'wire_routing_number') {
                sortCodeLabel = 'Wire Routing Number'
            }
            setState({
                fundingDetails: {
                    ...volopaAccountDetails,
                    volopaAccountHolderName: volopaAccountDetailsData?.settlement_accounts[0]?.bank_account_holder_name,
                    volopaAccountSortCode: volopaAccountDetailsData?.settlement_accounts[0]?.routing_code_value_1,
                    volopaAccountNumber: volopaAccountDetailsData?.settlement_accounts[0]?.account_number,
                    volopaAccountCountry: volopaAccountDetailsData?.settlement_accounts[0]?.bank_country,
                    volopaAccountAddress: volopaAccountDetailsData?.settlement_accounts[0]?.bank_address,
                    volopaAccountName: volopaAccountDetailsData?.settlement_accounts[0]?.bank_name,
                    volopaAccountPaymentRef: volopaAccountDetailsData?.short_reference,
                    volopaAccountIban: volopaAccountDetailsData?.settlement_accounts[0]?.iban,
                    volopaAccountSwift: volopaAccountDetailsData?.settlement_accounts[0]?.bic_swift,
                    volopaAccountSortCodeLabel: sortCodeLabel,
                    short_reference: volopaAccountDetailsData?.short_reference
                },
                fundingLimitReached: true
            });
        }
    }

    useEffect(() => {
        return () => {
            setState({ saveLoading: false })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setState({
            fundingLimitReached: false,
            showPaymentsUnder: false,
            showPaymentsOver: false
        });
        fetchFundingDetails()
        // eslint-disable-next-line
    }, [data])

    const historyData = [
        {
            title: 'ID',
            data: data?.id
        },
        {
            title: 'Batch ID',
            data: batchId
        },
        {
            title: 'Submitted',
            data: moment(data?.date_payment, 'YYYY-MM-DD').format('MMMM D, YYYY') //'13:10:14 - 18/02/2022'
        },
        {
            title: 'Status',
            data: data?.opt_payment_status.option ?? (data?.opt_payment_status.option === "Paid" || data?.opt_payment_status.option === "paid" ? "Payment Complete" : data?.opt_payment_status.option?.charAt(0)?.toUpperCase() + data?.opt_payment_status.option?.slice(1)),
            type: (data?.client_facing_status === "Payment Complete" || data?.payment_status?.option === "Paid" || data?.payment_status?.option === "paid") ? 'success' : 'danger'
        },
        {
            title: 'You Send Us',
            data: <Space size={4}>
                <Image src={FLAGS[data?.currency_sell?.currency]} alt={data?.data?.currency_sell?.currency} width={24} preview={false} />
                {accountingFormat(data?.client_sell_amount)}
            </Space>
        },
        {
            title: 'Recipient Receives',
            data: <Space size={4}>
                <Image src={FLAGS[data?.currency_buy?.currency]} alt={data?.currency_buy?.currency} width={24} preview={false} />
                {accountingFormat(data?.client_buy_amount)}
            </Space>
        },
        {
            title: 'Exchange Rate(' + data?.market_rate + ':' + data?.inverse_rate + ')',//Exchange Rate(GBP:EUR)'
            data: parseFloat(data?.composite_rate)?.toFixed(6) + ' (' + data?.market_rate + ': ' + data?.inverse_rate + ') ' + parseFloat(data?.composite_rate).toFixed(6) //'1.177024 (EUR: GBP)0.849618'
        },
        {
            title: 'Settlement Method',
            data: "Manual (Payment Fee: " + data?.additional_fees + ")"
        },
        {
            title: 'Expected Delivery Date',
            data: data?.date_delivery ? moment(data?.date_delivery).format(FORMATS.date) : '-'
        },
        {
            title: 'Settlement Date',
            data: data?.date_settlement ? moment(data?.date_settlement).format(FORMATS.date) : '-'
        },
        {
            title: 'Recipient',
            data: data?.fx_deal_beneficiary?.beneficiary_account?.account?.account_name
        },
        {
            title: 'Reason For Payment',
            data: data?.reason_for_transaction
        },
        {
            title: 'Payment Reference',
            data: data?.deal_reference
        },
        {
            title: 'Funding Method',
            data: 'Push Funds'
        },
        /*
        I946 Hide Commission
        {
            title: 'Commission Percentage',
            data: data?.commission_percentage ? data?.commission_percentage + "%" : '-'
        },
        */
    ];


    const downLoadPDF = async () => {
        const resp = await extendedApiRequest({
            baseUrl: process.env.REACT_APP_API_BASE_DOWNLOAD_URL,
            path: 'manualPaymentReport?id=' + data?.id,
            method: 'GET',
            config: {
                responseType: "blob",
            },

        });
        const acceptedFileTypes = {
            'application/pdf': '.pdf',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
            'text/csv': '.csv',
        };

        if (!resp.size || !resp.type || !Object.keys(acceptedFileTypes).includes(resp.type)) {
            notification.error({ message: 'Something went wrong!', placement: 'top' });
            return;
        }
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(resp);
        link.download = `${data?.id}.pdf`;
        link.click();
    }

    return (
        <>
            <Spin size="large" spinning={activateLoading}>
                <Row>
                    <Col span={24}>
                        <PageDescription title='Specific Manual Trade History' text='- Details of Manual Trade/Payment History' />
                    </Col>
                </Row>
                {(isFetching || loading) ?
                    <Row justify="center" className="m-t-20">
                        <Col>
                            <Spin size="large" spinning />
                        </Col>
                    </Row> :
                    <>
                        <Row className="m-t-20" gutter={[48, 12]} justify='center'>
                            <Col xxl={12} xl={12} lg={14} md={16} sm={20} xs={24}>
                                {historyData && historyData.length > 0 && historyData.map((val, key) => (
                                    <Card
                                        className={(key % 2 === 0 ? 'b-g' : 'b-none') + ' bg-none small-rounded'}
                                        bodyStyle={{ padding: '10px 20px' }}
                                        key={key}
                                    >
                                        <Row gutter={12}>
                                            <Col span={12}>
                                                <Typography.Text className="fs-18px medium">{val.title}</Typography.Text>
                                            </Col>
                                            <Col span={12}>
                                                <Typography.Text className="fs-18px medium" type={val.type}>{val.data}</Typography.Text>
                                            </Col>
                                        </Row>
                                    </Card>
                                ))}
                            </Col>
                            <Col xxl={12} xl={12} lg={14} md={16} sm={20} xs={24} >
                                <Row justify="end">
                                    <Button type="primary" onClick={() => downLoadPDF()}>
                                        <Space>
                                            <FaFileDownload size={18} style={{ marginBottom: "-2px" }} /> Download PDF Statement
                                        </Space>
                                    </Button>
                                </Row>
                                <Row className="m-t-20">
                                    <Row className="m-t-20">
                                        <Col span={24}>
                                            <Typography.Text className="fs-20px dark-green medium">Funding Bank Account</Typography.Text>
                                            <Card className="bg-light-grey" bodyStyle={{ padding: '10px' }}>
                                                <Row className='m-t-10' gutter={[24, 12]} justify='space-between'>
                                                    {showPaymentsUnder && <Col flex='auto'>
                                                        <Row justify='center'>
                                                            <Col>
                                                                <Button
                                                                    size='small'
                                                                    type='primary'
                                                                    className={!fundingLimitReached ? 'bg-light-green b-g medium' : ''}
                                                                    ghost={fundingLimitReached}
                                                                    onClick={(e) => updateFundingDetails(e, false)}
                                                                >
                                                                    {config.is_eea === 1 ? "Regular/SEPA Payment Up To A Value Of EUR 250,000" : "Regular / Faster Payment Up To A Value Of £250,000.00"}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>}
                                                    {showPaymentsOver && <Col flex='auto'>
                                                        <Row justify='center'>
                                                            <Col>
                                                                <Button
                                                                    size='small'
                                                                    type='primary'
                                                                    ghost={!fundingLimitReached}
                                                                    className={fundingLimitReached ? 'bg-light-green b-g medium' : ''}
                                                                    onClick={(e) => updateFundingDetails(e, true)}
                                                                >
                                                                    {config.is_eea === 1 ? "Payments Over EUR 250,00 Or SWIFT/TARGET2 Payments" : "Payments Over £250,000.00 Or BACS/CHAPS/SWIFT Payments"}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>}
                                                </Row>
                                                <Row gutter={[12, 12]} justify="space-between">
                                                    <Col span={12}>
                                                        <Space size={0} direction='vertical'>
                                                            <Typography.Text className="dark-green fs-18px muli semi-bold">Account Holder Name</Typography.Text>
                                                            <Typography.Text className="dark-green fs-18px muli light">{fundingDetails?.volopaAccountHolderName}</Typography.Text>
                                                        </Space>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row justify="space-between" gutter={[12, 12]}>
                                                            <Col>
                                                                {fundingDetails?.fundingLimitReached ?
                                                                    <Space direction="vertical" size={0}>
                                                                        <Typography.Text className="muli semi-bold fs-18px dark-green">Account Number</Typography.Text>
                                                                        <Typography.Text className="muli light fs-18px">{fundingDetails?.volopaAccountNumber}</Typography.Text>
                                                                    </Space> :
                                                                    <Space direction="vertical" size={0}>
                                                                        <Typography.Text className="muli semi-bold fs-18px dark-green">{fundingDetails?.volopaAccountNumberType === "iban" ? "IBAN" : "Account Number"}</Typography.Text>
                                                                        <Typography.Text className="muli light fs-18px">{fundingDetails?.volopaAccountNumber}</Typography.Text>
                                                                    </Space>
                                                                }
                                                            </Col>
                                                            <Col>
                                                                <Space size={0} direction='vertical'>
                                                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>{fundingDetails?.volopaAccountSortCodeLabel}</Typography.Text>
                                                                    <Typography.Text className="dark-green fs-18px muli light">{fundingDetails?.volopaAccountSortCode}</Typography.Text>
                                                                </Space>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                {fundingDetails?.fundingLimitReached &&
                                                    <Row className='m-t-10'>
                                                        <Col span={16}>
                                                            <Space direction='vertical' size={0}>
                                                                <Typography.Text className='muli semi-bold dark-green fs-18px'>IBAN</Typography.Text>
                                                                <Typography.Text className='muli fs-18px light dark-green'>{fundingDetails?.volopaAccountIban}</Typography.Text>
                                                            </Space>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Space direction='vertical' size={0}>
                                                                <Typography.Text className='muli semi-bold dark-green fs-18px'>SWIFT/BIC</Typography.Text>
                                                                <Typography.Text className='muli fs-18px light dark-green'>{fundingDetails?.volopaAccountSwift}</Typography.Text>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                }
                                                <Row gutter={12} className="m-t-10">
                                                    <Col span={12}>
                                                        <Space size={0} direction='vertical'>
                                                            <Typography.Text className="dark-green fs-18px muli semi-bold">Bank Name</Typography.Text>
                                                            <Typography.Text className="dark-green fs-18px muli light">{fundingDetails?.volopaAccountName}</Typography.Text>
                                                        </Space>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row justify="space-between" gutter={[12, 12]}>
                                                            <Col>
                                                                <Space size={0} direction='vertical'>
                                                                    <Typography.Text className="dark-green fs-18px muli semi-bold">Bank Address</Typography.Text>
                                                                    <Typography.Text className="dark-green fs-18px muli light">{fundingDetails?.volopaAccountAddress}</Typography.Text>
                                                                </Space>
                                                            </Col>
                                                            {fundingLimitReached && <Col>
                                                                <Space size={0} direction='vertical'>
                                                                    <Typography.Text className="dark-green fs-18px muli semi-bold">Mandatory Funding Reference</Typography.Text>
                                                                    <Typography.Text className="dark-green fs-18px muli light">{fundingDetails?.short_reference}</Typography.Text>
                                                                </Space>
                                                            </Col>}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row gutter={12} className="m-t-10">
                                                    <Col span={24}>
                                                        {!fundingDetails?.fundingLimitReached ?
                                                            <Space direction='vertical' size={0}>
                                                                {fundingDetails?.volopaAccountCountry !== "" &&
                                                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>Bank Country</Typography.Text>
                                                                }
                                                                <Typography.Text className='muli fs-18px light dark-green'>{fundingDetails?.volopaAccountCountry}</Typography.Text>
                                                            </Space> :
                                                            <Space direction='vertical' size={0}>
                                                                <Typography.Text className='muli semi-bold dark-green fs-18px'>Payment Reference</Typography.Text>
                                                                <Typography.Text className='muli fs-18px light dark-green'>{fundingDetails?.volopaAccountPaymentRef}</Typography.Text>
                                                            </Space>
                                                        }
                                                    </Col>
                                                </Row>
                                                {fundingDetails?.fundingLimitReached && <Typography.Text className='muli semi-bold dark-green fs-12px'>*Please note that the Payment Reference is required to ensure we identify your funds efficiently</Typography.Text>}
                                                {config.is_eea === 1 && <Row className='m-t-10 m-b-10'><Typography.Text className='muli semi-bold dark-green fs-12px'>Please ensure that you transfer the funds from the bank account which you have verified. Please note that sending the funds from a different bank account may result in the payment being declined.</Typography.Text></Row>}
                                            </Card>
                                        </Col>
                                    </Row>

                                </Row>
                            </Col>
                        </Row>
                    </>
                }
            </Spin>
        </>
    )
}

export default SpecificManualTradeHistory;
