import create from "zustand";

const useConfigurationState = create((set, get) => ({
    syncCardTrans: true,
    syncBankFeeds: false,
    syncExpenses: false,
    allCards: null,
    categoryMap: [],
    codatBankAccountId: null,
    codatBillPaymentBankAccountId: null,
    retryCount: 0,
    clientId: null,
    newAccountName: '',
    newAccount: false,
    codatCategoriesQueryLoading: true,
    statusId: 0,
    pollForStatusInterval: 0,
    accountingLogo: null,
    clientCodatCompanyMapId: null, // ?
    selectCardsModal: false,
    selectedCards: [],
    searchCardsQuery: null,
    disconectConfirmModal: {
        show: false,
        loading: false,
        message: `Do you really want to disconnect Volopa from your accounting software?`,
    },
    loading: false,
    refreshDataLoading: false,
    checkingRefreshState: false,
    syncExpenseInformation: true,

    // Actions
    //
    setState: (data) => set(() => data),

    resetState: () => set({
        syncCardTrans: true,
        syncBankFeeds: false,
        syncExpenses: false,
        allCards: null,
        categoryMap: [],
        codatBillPaymentBankAccountId: null,
        retryCount: 0,
        clientId: null,
        newAccountName: '',
        newAccount: false,
        codatCategoriesQueryLoading: true,
        statusId: 0,
        pollForStatusInterval: 0,
        accountingLogo: null,
        clientCodatCompanyMapId: null, // ?
        selectCardsModal: false,
        selectedCards: [],
        searchCardsQuery: null,
        disconectConfirmModal: {
            show: false,
            loading: false,
            message: `Do you really want to disconnect Volopa from your accounting software?`,
        },
        loading: false,
        refreshDataLoading: false,
        checkingRefreshState: false,
        syncExpenseInformation: true,
    }),

    //
    addCategoryMapping: (mapping) => {
        set(() => ({
            categoryMap: [
                ...get().categoryMap,
                mapping,
            ]
        }));
    },

    //
    delCategoryMapping: (mapId) => {
        set({ categoryMap: get().categoryMap.filter((map) => map.id !== mapId) })
    },

    //
    setDisconnectConfirmModalState: (key, val) => {
        set(() => ({
            disconectConfirmModal: {
                ...get().disconectConfirmModal,
                [key]: val,
            }
        }));
    },

    //
    resetDisconnectConfirmModal: () => set(() => ({
        disconectConfirmModal: {
            show: false,
            loading: false,
            message: `Do you really want to disconnect Volopa from your accounting software?`,
        }
    })),
}));

export {
    useConfigurationState,
};
