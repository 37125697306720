/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Login from "App/Pages/Login";
import Configuration from "App/Pages/Accounting/Configuration";
import AccountingExpenseTransactions from "App/Pages/Accounting/Transactions/Expenses";
import AccountingBankFeedTransactions from "App/Pages/Accounting/Transactions/BankFeeds";
import BulkCardControls from "App/Pages/Cards/BulkCardControls";
import BulkDailyLimits from "App/Pages/Cards/BulkCardControls/BulkDailyLimits";
import BulkAutoFund from "App/Pages/Cards/BulkCardControls/BulkAutoFund";
import BulkTxLimits from "App/Pages/Cards/BulkCardControls/BulkTxLimits";
import BulkPaymentTypes from "App/Pages/Cards/BulkCardControls/BulkPaymentTypes";
import Cards from "App/Pages/Cards/Cards";
import ManageSpecificCard from "App/Pages/Cards/Cards/ManageSpecificCard";
import CardsDashboard from "App/Pages/Cards/CardsDashboard";
import TransactionHistory from "App/Pages/Cards/TransactionHistory";
import SpecificCardTransaction from "App/Pages/Cards/TransactionHistory/SpecificCardTransaction";
import AddRecipient from "App/Pages/Payments/AddRecipient";
import BatchPayments from "App/Pages/Payments/BatchPayments";
import PayMultipleRecipients from "App/Pages/Payments/BatchPayments/PayMultipleRecipients";
import NewPayment from "App/Pages/Payments/NewPayment";
import PaymentHistory from "App/Pages/Payments/PaymentHistory";
import PaymentsDashboard from "App/Pages/Payments/PaymentsDashboard";
import RecipientList from "App/Pages/Payments/RecipientList";
import AdditionalServices from "App/Pages/Settings/AdditionalServices";
import CompanyAccount from "App/Pages/Settings/CompanyAccount";
import PersonalProfile from "App/Pages/Settings/PersonalProfile";
import BankAccounts from "App/Pages/Wallet/BankAccounts";
import ConvertBalances from "App/Pages/Wallet/ConvertBalances";
import FundingHistory from "App/Pages/Wallet/FundingHistory";
import FundWallet from "App/Pages/Wallet/FundWallet";
import WalletDashboard from "App/Pages/Wallet/WalletDashboard";
import ConvertCardBalances from 'App/Pages/Cards/Cards/ManageSpecificCard/ConvertBalances';
import URLS from './constants'
import NewCardWithdrawal from "App/Pages/Cards/Cards/ManageSpecificCard/NewCardWithdrawal";
import NewCardFund from "App/Pages/Cards/Cards/ManageSpecificCard/NewCardFund";
import AutoFund from "App/Pages/Cards/Cards/AutoFund";
import UserRolesAndPermissions from "App/Pages/Settings/CompanyAccount/UserRolesAndPermissions";
import AddNewUser from "App/Pages/Settings/CompanyAccount/ManageUsers/AddNewUser";
import ManageUsers from "App/Pages/Settings/CompanyAccount/ManageUsers";
import UploadUserList from "App/Pages/Settings/CompanyAccount/ManageUsers/UploadUserList";
import ManageSpecificUser from "App/Pages/Settings/CompanyAccount/ManageUsers/ManageSpecificUser";
import RequestCard from "App/Pages/Settings/CompanyAccount/ManageUsers/ManageSpecificUser/RequestCard";
import PaymentSummary from "App/Pages/Payments/BatchPayments/PayMultipleRecipients/PaymentSummary";
import AccountingConnect from "App/Pages/Accounting/AccountingConnect";
import SpecificPaymentHistory from "App/Pages/Payments/PaymentHistory/SpecificPaymentHistory";
import ForgotPassword from "App/Pages/Login/ForgotPassword";
import YourApplication from "App/Pages/Settings/CompanyAccount/YourApplication";
import RecipientDetails from "App/Pages/Payments/RecipientList/RecipientDetails";
import Help from "App/Pages/Settings/CompanyAccount/Help";
import UserGuides from "App/Pages/Settings/CompanyAccount/Help/UserGuides";
import GettingStarted from "App/Pages/Settings/CompanyAccount/Help/UserGuides/GettingStarted";
import BankToBankDeliveryTimescales from "App/Pages/Settings/CompanyAccount/Help/UserGuides/BankToBankDeliveryTimescales";
import VerifyingYourBeneficiaryBank from "App/Pages/Settings/CompanyAccount/Help/UserGuides/VerifyingYourBeneficiaryBank";
import UserPermissions from "App/Pages/Settings/CompanyAccount/Help/UserGuides/UserPermissions";
import FAQs from "App/Pages/Settings/CompanyAccount/Help/FAQs";
import VolopaCardFAQs from "App/Pages/Settings/CompanyAccount/Help/FAQs/VolopaCardFAQs";
import Terms from "App/Pages/Settings/CompanyAccount/Help/Terms";
import ContactUs from "App/Pages/Settings/CompanyAccount/Help/ContactUs";
import ContactDetails from "App/Pages/Settings/CompanyAccount/Help/ContactUs/ContactDetails";
import Notifications from "App/Pages/Notifications";
import ResetPassword from "App/Pages/Login/ResetPassword";
import NotificationSettings from "App/Pages/Settings/CompanyAccount/NotificationSettings";
import RefundDetails from "App/Pages/Settings/CompanyAccount/RefundDetails";
import PersonalCardsDashboard from "App/Pages/Cards/PersonalCardsDashboard";
import StatementsAndReports from "App/Pages/Cards/TransactionHistory/StatementsAndReports";

import AppLayout from "App/Layouts/AppLayout";
import { USER_ROLES } from "Constants/UserRoles";

import {
	AccountingConnectedGuard,
	AccountingNotConnectedGuard,
	AuthGuard,
	RoleGuard,
	AdditionalServicesGuard,
	ActiveClientGuard,
	ApprovedUserGuard,
} from "./Guards";
import { ErrorView } from "App/Components/ErrorView";
import { ChangeEmail } from "App/Pages/Settings/PersonalProfile/ChangeEmail";
import { EnableTwoFactor } from "App/Pages/Settings/PersonalProfile/EnableTwoFactor";
import { useLoginState } from "App/Pages/Login/store";
import FundingDetails from "App/Pages/Wallet/FundingDetails";
import { Impersonate } from "App/Pages/Login/Impersonate";
import EmailConfirmation from "App/Pages/Login/EmailConfirmation";
import { DownloadAttachment } from "App/Pages/Cards/TransactionHistory/StatementsAndReports/DownloadAttachment";
import ManualTradeHistory from "App/Pages/Payments/ManualTradeHistory";
import SpecificManualTradeHistory from "App/Pages/Payments/ManualTradeHistory/SpecificManualTradeHistory";
import { WorkflowApproval } from "App/Pages/Settings/CompanyAccount/NotificationSettings/WorkflowApproval";
import { DraftPayments } from "App/Pages/Payments/PaymentHistory/DraftPayments";
import PaymentReports from "App/Pages/Payments/PaymentHistory/PaymentReports";
import FxForwardsDashboard from "App/Pages/FxForwards/FxForwardsDashboard";

function RouteManager() {
	const location = useLocation();
	const history = useLoginState(s => s.history);
	const setLoginState = useLoginState(s => s.setState);

	// Storing location history
	useEffect(() => {
		setLoginState({ history: [location.pathname, ...history] })
	}, [location.pathname]);

	return (
		<Routes>
			<Route path={URLS.Login} element={<Login />} />
			<Route path={URLS.ForgotPassword} element={<ForgotPassword />} />
			<Route path={URLS.ResetPassword} element={<ResetPassword />} />
			<Route path={URLS.Impersonate + '/:token'} element={<Impersonate />} />

			<Route element={<AuthGuard />}>
				<Route element={<AppLayout />}>
					{/* Check if client is active */}
					<Route element={<ApprovedUserGuard />}>
						<Route element={<ActiveClientGuard />}>

							{/* Notifications (No role guard)========================= */}
							<Route path={URLS.Notifications} element={<Notifications />} />

							{/* Settings (No role guard)========================= */}
							<Route path={URLS.Help} element={<Help />} />
							<Route path={URLS.UserGuides} element={<UserGuides />} />
							<Route path={URLS.GettingStarted} element={<GettingStarted />} />
							<Route path={URLS.BankToBankDeliveryTimescales} element={<BankToBankDeliveryTimescales />} />
							<Route path={URLS.VerifyingYourBeneficiaryBank} element={<VerifyingYourBeneficiaryBank />} />
							<Route path={URLS.UserPermissions} element={<UserPermissions />} />
							<Route path={URLS.FAQs} element={<FAQs />} />
							<Route path={URLS.VolopaCardFAQs} element={<VolopaCardFAQs />} />
							<Route path={URLS.Terms} element={<Terms />} />
							<Route path={URLS.ContactUs} element={<ContactUs />} />
							<Route path={URLS.ContactDetails} element={<ContactDetails />} />
							<Route path={URLS.YourApplication} element={<YourApplication />} />
							<Route path={URLS.ChangeEmail + '/:userId'} element={<ChangeEmail />} />
							<Route path={URLS.EnableTwoFactor} element={<EnableTwoFactor />} />

							{/* Cards (No role guard)========================= */}
							<Route element={<AdditionalServicesGuard serviceKey="cards_status" />}>
								<Route path={URLS.TransactionHistory} element={<TransactionHistory />} />
								<Route path={URLS.SpecificCardTransaction + '/:txId'} element={<SpecificCardTransaction />} />
							</Route>

							{/* Card user & Business user guarded ========================= */}
							<Route element={<RoleGuard roles={[USER_ROLES.card_user.roleId, USER_ROLES.business_user.roleId]} />}>
								<Route element={<AdditionalServicesGuard serviceKey="cards_status" />}>
									<Route path={URLS.PersonalCardsDashboard} element={<PersonalCardsDashboard />} />
								</Route>
							</Route>

							{/* Business user, Primary admin & admin guarded ========================= */}
							<Route element={<RoleGuard roles={[USER_ROLES.primary_admin.roleId, USER_ROLES.admin.roleId, USER_ROLES.business_user.roleId]} />}>
								{/* Payments ========================= */}
								<Route element={<AdditionalServicesGuard serviceKey="payments_status" />}>
									<Route path={URLS.PaymentsDashboard} element={<PaymentsDashboard />} />
									<Route path={URLS.RecipientList} element={<RecipientList />} />
									<Route path={URLS.AddRecipient} element={<AddRecipient />} />
									<Route path={URLS.NewPayment + '/:mapId?'} element={<NewPayment />} />
									<Route path={URLS.BatchPayments} element={<BatchPayments />} />
									<Route path={URLS.PayMultipleRecipients + '/:uuid?'} element={<PayMultipleRecipients />} />
									<Route path={URLS.PaymentSummary + '/:mapId?'} element={<PaymentSummary />} />
									<Route path={URLS.PaymentHistory} element={<PaymentHistory />} />
									<Route path={URLS.DraftPayments} element={<DraftPayments />} />
									<Route path={URLS.RecipientDetails + '/:id'} element={<RecipientDetails />} />
									<Route path={URLS.SpecificPaymentHistory + '/:mapId'} element={<SpecificPaymentHistory />} />
									<Route path={URLS.ManualTradeHistory} element={<ManualTradeHistory />} />
									<Route path={URLS.SpecificManualTradeHistory + '/:mapId'} element={<SpecificManualTradeHistory />} />
									<Route path={URLS.PaymentReports} element={<PaymentReports />} />
								</Route>

								{/* Fx Forwards ==================== */}
								<Route element={<AdditionalServicesGuard serviceKey="fx_forwards_status" />}>
									<Route path={URLS.FxForwardsDashboard} element={<FxForwardsDashboard />} />
								</Route>
							</Route>

							{/* Primary admin & admin guarded ========================= */}
							<Route element={<RoleGuard roles={[USER_ROLES.primary_admin.roleId, USER_ROLES.admin.roleId]} />}>
								{/* Wallet ========================= */}
								<Route element={<AdditionalServicesGuard serviceKey="company_wallet_status" />}>
									<Route path={URLS.WalletDashboard} element={<WalletDashboard />} />
									<Route path={URLS.FundWallet} element={<FundWallet />} />
									<Route path={URLS.ConvertWalletBalances} element={<ConvertBalances />} />
									<Route path={URLS.FundingHistory} element={<FundingHistory />} />
									<Route path={URLS.FundingDetails + '/:fundingId'} element={<FundingDetails />} />
									<Route path={URLS.BankAccounts} element={<BankAccounts />} />
								</Route>

								{/* Cards ========================= */}
								<Route element={<AdditionalServicesGuard serviceKey="cards_status" />}>

									<Route path={URLS.CardsDashboard} element={<CardsDashboard />} />
									<Route path={URLS.AutoFund + '/:cardId'} element={<AutoFund />} />
									<Route path={URLS.ConvertCardBalances + '/:cardId'} element={<ConvertCardBalances />} />
									<Route path={URLS.NewCardWithdrawal + '/:cardId'} element={<NewCardWithdrawal />} />
									<Route path={URLS.NewCardFund + '/:cardId'} element={<NewCardFund />} />
									<Route path={URLS.Cards} element={<Cards />} />
									<Route path={URLS.ManageSpecificCard + '/:cardId'} element={<ManageSpecificCard />} />
									<Route path={URLS.StatementsAndReports} element={<StatementsAndReports />} />

									<Route path={URLS.BulkCardControls} element={<BulkCardControls />}>
										<Route path={URLS.BulkCardControlsDailyLimits} element={<BulkDailyLimits />} />
										<Route path={URLS.BulkCardControlsAutoFund} element={<BulkAutoFund />} />
										<Route path={URLS.BulkCardControlsTxLimits} element={<BulkTxLimits />} />
										<Route path={URLS.BulkCardControlsPaymentTypes} element={<BulkPaymentTypes />} />
									</Route>
								</Route>

								<Route element={<AdditionalServicesGuard serviceKey='accounting_status' />}>
									<Route element={<AccountingNotConnectedGuard />}>
										<Route path={URLS.AccountingConnect} element={<AccountingConnect />} />
									</Route>

									<Route element={<AccountingConnectedGuard />}>
										<Route path={URLS.ExpenseTransactions} element={<AccountingExpenseTransactions />} />
										<Route path={URLS.BankFeedTransactions} element={<AccountingBankFeedTransactions />} />
										<Route path={URLS.Configuration} element={<Configuration />} />
									</Route>
								</Route>
							</Route>

							{/* Primary admin & admin guarded settings ========================= */}
							<Route element={<RoleGuard roles={[USER_ROLES.primary_admin.roleId, USER_ROLES.admin.roleId]} redirectTo={URLS.PersonalProfile} />}>
								{/* Settings (Role protected)========================= */}
								<Route path={URLS.CompanyAccount} element={<CompanyAccount />} />
								<Route path={URLS.RefundDetails} element={<RefundDetails />} />
								<Route path={URLS.RefundRecipientDetails + '/:id'} element={<RecipientDetails />} />
								<Route path={URLS.ManageUsers} element={<ManageUsers />} />
								<Route path={URLS.ManageSpecificUser + '/:userId'} element={<ManageSpecificUser />} />
								<Route path={URLS.RequestCard} element={<RequestCard />} />
								<Route path={URLS.ChangeUserEmail + '/:userId'} element={<ChangeEmail />} />
								<Route path={URLS.UploadUserList} element={<UploadUserList />} />
								<Route path={URLS.AddNewUser} element={<AddNewUser />} />
								<Route path={URLS.UserRolesAndPermissions} element={<UserRolesAndPermissions />} />
								<Route path={URLS.NotificationSettings} element={<NotificationSettings />} />
								<Route path={URLS.WorkflowApproval} element={<WorkflowApproval />} />
							</Route>
						</Route>
						{/* Accessible by clients that are not active */}
						<Route element={<RoleGuard roles={[USER_ROLES.primary_admin.roleId, USER_ROLES.admin.roleId]} redirectTo={URLS.Error} />}>
							<Route path={URLS.AdditionalServices} element={<AdditionalServices />} />
						</Route>
					</Route>
					{/* Accessible by users that are not approved */}
					<Route element={<RoleGuard roles={[USER_ROLES.primary_admin.roleId, USER_ROLES.admin.roleId, USER_ROLES.business_user.roleId, USER_ROLES.card_user.roleId]} redirectTo={URLS.Error} />}>
						<Route path={URLS.PersonalProfile} element={<PersonalProfile />} />
					</Route>
				</Route>
				{/* App layout end */}
				<Route path={`${URLS.DownloadAttachment}/:uuid`} element={<DownloadAttachment />} />
				<Route path={URLS.EmailConfirmation} element={<EmailConfirmation />} />
			</Route>
			<Route path={URLS.Error} element={<ErrorView title={['Oops! Looks like Something went wrong.', 'Please contact Volopa Support.']} exclamation />} />
			<Route path="/" element={<Navigate to={URLS.WalletDashboard} replace />} />
			<Route path="*" element={<ErrorView title="404 Page Not Found." />} />
		</Routes>
	)
};

export { RouteManager };
