import AED from "Assets/Images/flags/AED.png";
import AUD from "Assets/Images/flags/AUD.png";
import BHD from "Assets/Images/flags/BHD.png";
import CAD from "Assets/Images/flags/CAD.png";
import CHF from "Assets/Images/flags/CHF.png";
import CNY from "Assets/Images/flags/CNY.png";
import CZK from "Assets/Images/flags/CZK.png";
import DKK from "Assets/Images/flags/DKK.png";
import EUR from "Assets/Images/flags/EUR.png";
import GBP from "Assets/Images/flags/GBP.png";
import HKD from "Assets/Images/flags/HKD.png";
import HUF from "Assets/Images/flags/HUF.png";
import ILS from "Assets/Images/flags/ILS.png";
import INR from "Assets/Images/flags/INR.png";
import JPY from "Assets/Images/flags/JPY.png";
import KES from "Assets/Images/flags/KES.png";
import KWD from "Assets/Images/flags/KWD.png";
import MXN from "Assets/Images/flags/MXN.png";
import NOK from "Assets/Images/flags/NOK.png";
import NZD from "Assets/Images/flags/NZD.png";
import OMR from "Assets/Images/flags/OMR.png";
import PLN from "Assets/Images/flags/PLN.png";
import QAR from "Assets/Images/flags/QAR.png";
import SEK from "Assets/Images/flags/SEK.png";
import SGD from "Assets/Images/flags/SGD.png";
import SWF from "Assets/Images/flags/SWF.png";
import THB from "Assets/Images/flags/THB.png";
import TRY from "Assets/Images/flags/TRY.png";
import USD from "Assets/Images/flags/USD.png";
import ZAR from "Assets/Images/flags/ZAR.png";

import arrows from "Assets/Images/arrows.svg";
import card from "Assets/Images/card.svg";
import products from "Assets/Images/products.svg";
import scroll from "Assets/Images/scroll.svg";
import wallet from "Assets/Images/wallet.svg";
import currencyConversion from "Assets/Images/currencyConversion.png";
import errorIcon from "Assets/Images/errorIcon.svg";
import question from "Assets/Images/question.svg";
import stampSolid from "Assets/Images/stamp-solid.svg";
import calendar from "Assets/Images/calendar.svg";

const FLAGS = {
	AED,
	AUD,
	BHD,
	CAD,
	CHF,
	CNY,
	CZK,
	DKK,
	EUR,
	GBP,
	HKD,
	HUF,
	ILS,
	INR,
	JPY,
	KES,
	KWD,
	MXN,
	NOK,
	NZD,
	OMR,
	PLN,
	QAR,
	SEK,
	SGD,
	SWF,
	THB,
	TRY,
	USD,
	ZAR,
};

const IMAGES = {
	arrows,
	card,
	products,
	scroll,
	wallet,
	currencyConversion,
	errorIcon,
	question,
	stampSolid,
	calendar
};

export { FLAGS, IMAGES }
