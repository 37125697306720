import { Button, Col, Image, Input, Menu, Row, Space, Typography, Modal, notification, Radio } from "antd";
import PageDescription from "../../../Components/PageDescription";
import { FaFileDownload, FaPaperclip } from 'react-icons/fa';
import CustomTable from "../../../Components/CustomTable";
import { useNavigate } from 'react-router-dom';
import URLS from '../../../../Routes/constants';
import { useAllCategories, useAllCurrencies, useAllTransactionTypes, useGetAllCards, useTransactionsHistoryQuery } from "App/Pages/Cards/TransactionHistory/query";
import moment from "moment";
import CustomPagination from "App/Components/CustomPagination";
import { useTransactionsHistoryState } from "App/Pages/Cards/TransactionHistory/store";
import { accountingFormat } from "Utils";
import { debounce, uniqueId } from "lodash";
import { useEffect, useState } from "react";
import { urlObjectCreator } from "Utils";
import useReportsApi from "Hooks/ReportsAPI";
import { FLAGS } from "Constants/Images";
import { FORMATS } from "Constants/Formats";
import { useLoginState } from "App/Pages/Login/store";
import { USER_ROLES } from "Constants/UserRoles";
import { AiFillFilter } from "react-icons/ai";

function TransactionHistory() {
    const { data: response, isLoading, isFetching } = useTransactionsHistoryQuery();
    const { data: currencies } = useAllCurrencies();
    const { data: categories } = useAllCategories();
    const { data: cards } = useGetAllCards();
    const { data: transactionTypes } = useAllTransactionTypes();
    const currentPage = useTransactionsHistoryState(state => state.currentPage);
    const currencyId = useTransactionsHistoryState(state => state.currencyId);
    const transactionCategoryId = useTransactionsHistoryState(state => state.transactionCategoryId);
    const withAttachments = useTransactionsHistoryState(state => state.withAttachments);
    const transactionTypeId = useTransactionsHistoryState(state => state.transactionTypeId);
    const searchFilter = useTransactionsHistoryState(state => state.searchFilter);
    const prepaidCardId = useTransactionsHistoryState(state => state.prepaidCardId);
    const limit = useTransactionsHistoryState(state => state.limit);
    const setState = useTransactionsHistoryState(state => state.setState);
    const reportsApi = useReportsApi();
    const navigate = useNavigate();
    const [exportStatementModal, setExportStatementModal] = useState({
        show: false,
        url: null,
        extension: null,
    });
    // eslint-disable-next-line no-unused-vars
    const [exportLoading, setExportLoading] = useState(false);
    const roleId = useLoginState(state => state.roleId)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [orderedCCyForFilter, setOrderedCCyForFilter] = useState([]);

    useEffect(() => {
        if (!!currencies?.data?.length) {

            const topCCys = [
                currencies?.data?.find(c => c.id === 7),
                currencies?.data?.find(c => c.id === 8),
            ];

            const restCCys = currencies?.data?.filter(c => c.id !== 7 && c.id !== 8);

            restCCys?.sort((a, b) => a.sort_order_buy - b.sort_order_buy);

            setOrderedCCyForFilter([...topCCys, ...restCCys]?.map(currency => ({ text: currency?.currency, value: currency?.id })));
        }

    }, [currencies]);


    useEffect(() => {
        return () => {
            handleExportStatementModal();
            /* This is used reset pagination state at unmounting of component */
            // Commented to prevent reset of filters while navigating between pages (VBP-339)
            // setState({
            //     currentPage: 1,
            //     limit: 10,
            //     searchQuery: '',
            //     currencyId: null,
            //     transactionCategoryId: null,
            //     transactionTypeId: null,
            //     prepaidCardId: null,
            //     withAttachments: null,
            //     searchFilter: null,
            // })
        }
        // eslint-disable-next-line
    }, []);

    const handleExportStatementModal = () => {
        setExportStatementModal({
            show: false,
            url: null,
            extension: null,
        });
    }

    const filterSelection = (name, value) => {
        if (name === 'Currency') {
            setState({ currencyId: value, currentPage: 1 });
        } else if (name === 'Attachments') {
            setState({ withAttachments: value, currentPage: 1 });
        } else if (name === 'Transaction Category') {
            setState({ transactionCategoryId: value, currentPage: 1 });
        } else if (name === 'Transaction Type') {
            setState({ transactionTypeId: value, currentPage: 1 });
        } else if (name === 'Card') {
            setState({ prepaidCardId: value, currentPage: 1 });
        }
    }

    const getFilterProps = (name, filterState, options) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${name}`}
                    value={searchFilter}
                    className="m-b-5"
                    onChange={(e) => setState({ searchFilter: e.target.value })}
                />
                <div style={{ maxHeight: '400px', maxWidth: '200px', overflowY: 'auto' }}>
                    <Radio.Group
                        onChange={(e) => { confirm(); filterSelection(name, e?.target?.value) }}
                        value={filterState}
                    >
                        <Space direction="vertical">
                            {searchFilter ? options?.filter((option) => option.text.toLowerCase().includes(searchFilter?.toLowerCase()))?.map(option => (
                                <Radio key={option?.value} value={option?.value}>
                                    {option?.text}
                                </Radio>
                            )) : options?.map(option => (
                                <Radio key={option?.value} value={option?.value}>
                                    {option?.text}
                                </Radio>
                            ))}
                        </Space>
                    </Radio.Group>
                </div>
                <Button onClick={() => { confirm(); filterSelection(name, null) }} size="small" style={{ width: 90, marginTop: '8px' }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: _filtered => <AiFillFilter size={20} style={{ color: filterState !== null ? '#1890ff' : undefined }} />,
        onFilterDropdownVisibleChange: (visible) => {
            if (!visible) {
                setState({ searchFilter: '' }); // Clear the search filter when the dropdown is closed
            }
        },
    });

    const tableColumns = [
        {
            title: 'Date & Time',
            dataIndex: 'transaction_time',
            key: 'transaction_time',
            sorter: (a, b) => a.transaction_time && b.transaction_time && moment(a.transaction_time).diff(moment(b.transaction_time)),
            render: (value) =>
                <Space direction="vertical" size={0}>
                    <Typography>{moment(value).isValid() ? moment(value).format(FORMATS.date) : '-'}</Typography>
                    <Typography>{moment(value).isValid() ? moment(value).format(FORMATS.time) : ''}</Typography>
                </Space>
        },
        {
            title: 'Card',
            dataIndex: ['prepaid_card'],
            key: 'prepaid_card',
            render: (value, record) => <Space direction="vertical" size={0}>
                {!!value?.card_number?.includes('-MVC') ? (
                    <Typography>Company Wallet</Typography>
                ) : (
                    <>
                        <Typography>{value?.card_holder?.title} {value?.card_holder?.first_name} {value?.card_holder?.last_name}</Typography>
                        <Typography>**** **** **** {value?.card_number}</Typography>
                    </>
                )}
            </Space>,
            ...getFilterProps('Card', prepaidCardId, cards?.data?.card_list?.map(card => (
                {
                    text: !!card?.card_number?.includes('-MVC') ? 'Company Wallet' : (`${card?.title} ${card?.first_name ?? ''} ${card?.last_name ?? ''}
                **** **** **** ${card?.card_number?.slice(-4)}
                `),
                    value: card?.id
                }
            )) || []),
            onFilter: (value, record) => record.opt_transaction_type?.option === value,
        },
        {
            title: 'Transaction Type',
            dataIndex: ['opt_transaction_type', 'option'],
            key: 'opt_transaction_type_option',
            ...getFilterProps('Transaction Type', transactionTypeId, transactionTypes?.data?.map(txtype => ({ text: txtype?.option, value: txtype?.id })) || []),
            onFilter: (value, record) => record.opt_transaction_type?.option === value,
        },
        {
            title: 'Merchant Name',
            dataIndex: ['merchant_name'],
            key: 'merchant_name'
        },
        {
            title: 'Category',
            dataIndex: ['transaction_category', 'category', 'description'],
            key: 'transaction_category_category_description',
            align: 'center',
            render: (value) => <Space size={4}>
                {value ?? '-'}
            </Space>,
            ...getFilterProps('Transaction Category', transactionCategoryId, categories?.data?.map(category => ({ text: category?.description, value: category?.id })) || []),
            onFilter: (value, record) => record.opt_transaction_type?.option === value,
        },
        {
            title: 'Attachments',
            dataIndex: ['expense', 'file_store'],
            key: 'attachments',
            align: 'center',
            render: (value) => value?.length ? <FaPaperclip className="dark-green" /> : '-',
            ...getFilterProps('Attachments', withAttachments, [{ text: 'Attachment', value: 1 }, { text: 'No Attachment', value: 0 }]),
            onFilter: (value, record) => record.opt_transaction_type?.option === value,
        },
        {
            title: 'Currency',
            dataIndex: ['currency', 'currency'],
            key: 'currency',
            render: (value) => <Space size={4}>
                {FLAGS[value] && <Image src={FLAGS[value]} alt={value} width={24} preview={false} />}
                {value}
            </Space>,
            ...getFilterProps('Currency', currencyId, orderedCCyForFilter),
            onFilter: (value, record) => record.opt_transaction_type?.option === value,

        },
        {
            title: 'Credit / Debit',
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => (a.amount && b.amount) && a.amount - b.amount,
            render: (value, record) => <Typography.Text strong type={record.debit_credit === 1 ? 'success' : 'danger'}>
                {record.debit_credit === 1 ? '+' : '-'}{accountingFormat(value)}
            </Typography.Text>
        }
    ];

    //
    // eslint-disable-next-line no-unused-vars
    const exportDropdown = (
        <Menu
            onClick={async (item) => {
                if (response?.data) {
                    const payload = {
                        t_ids: [...response?.data.map((item) => item.id)],
                        extension: item.key
                    }

                    try {
                        setExportLoading(true);
                        const data = await reportsApi({
                            path: 'generateTransactionReportByIds',
                            method: 'POST',
                            data: payload,
                            config: {
                                responseType: "blob",
                            },
                        });

                        const acceptedFileTypes = {
                            'application/pdf': '.pdf',
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
                            'text/csv': '.csv',
                        };

                        if (!data.size || !data.type || !Object.keys(acceptedFileTypes).includes(data.type)) {
                            notification.error({ message: 'Something went wrong!', placement: 'top' });
                            return;
                        }

                        setExportStatementModal({
                            show: true,
                            url: urlObjectCreator(data),
                            extension: acceptedFileTypes[data.type]
                        });
                    } catch (err) {
                        notification.error({ message: 'Something went wrong!', placement: 'top' });
                    } finally {
                        setExportLoading(false);
                    }
                }
            }}
            items={[
                {
                    key: 'xlsx',
                    label: 'Excel export'
                },
                {
                    key: 'csv',
                    label: 'CSV export'
                },
                {
                    key: 'pdf',
                    label: 'PDF export'
                }
            ]}
        />
    );

    //
    const handlePageChange = (page) => {
        setState({ currentPage: page }, 'handlePageChange');
    }

    //
    const handlePageSizeChange = (current, size) => {
        setState({ limit: size, currentPage: 1 }, 'handlePageSizeChange');
    }

    //
    const handleSearchQuery = (evt) => {
        setState({ searchQuery: evt.target.value, currentPage: 1 });
    }

    const onChange = (_pagination, filters, _sorter, _extra) => {
        setState({
            currencyId: filters?.currency?.at(0) || null,
            transactionCategoryId: filters?.transaction_category_category_description?.at(0) || null,
            withAttachments: filters?.attachments?.at(0) || null,
            prepaidCardId: filters?.prepaid_card?.at(0) || null,
            transactionTypeId: filters?.opt_transaction_type_option?.at(0) || null,
            currentPage: 1
        })
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    //
    return (
        <>
            <Modal
                title="Transactions Statement Export"
                open={exportStatementModal.show}
                onCancel={handleExportStatementModal}
                footer={[
                    <Button key="back" onClick={handleExportStatementModal}>
                        Cancel
                    </Button>,
                ]}
            >
                {(!!exportStatementModal?.url && !!exportStatementModal?.extension) &&
                    <Row align="middle" justify="center" wrap gutter={[0, 20]}>
                        <Col span={24} align="middle" justify="center">
                            <Typography.Text className="muli semi-bold fs-18px dark-green">Your statement is ready for download</Typography.Text>
                            <br />
                            <Typography.Text>{'transactions-statement-' + moment().format('MMMM-Do-YYYY_Hmmss') + exportStatementModal.extension}</Typography.Text>
                        </Col>
                        <Col span={24} align="middle" justify="center">
                            <Button
                                type="primary"
                                href={exportStatementModal?.url || null}
                                download={'transactions-statements_' + moment().format('MMMM-Do-YYYY_Hmmss') + exportStatementModal.extension}
                                onClick={() => handleExportStatementModal()}
                                size="lg"
                            >
                                <FaFileDownload size={14} style={{ marginRight: '10px' }} /> Download statement
                            </Button>
                        </Col>
                    </Row>
                }
            </Modal>
            <Row>
                <Col span={24}>
                    <PageDescription title='Transaction History' text='- Overview Of All Transactions Made By Prepaid Cards Assigned To You' />
                </Col>
            </Row>
            <Row className="m-t-10" justify="space-between" gutter={[12, 12]}>
                <Col xxl={4}>
                    <Input.Search onChange={debounce(handleSearchQuery, 400)} placeholder="Search Type / Card Holder" />
                </Col>
                <Col>
                    <Space wrap>
                        {[USER_ROLES.admin.roleId, USER_ROLES.primary_admin.roleId].includes(roleId) &&
                            <Button onClick={() => navigate(`${URLS.StatementsAndReports}`)} type="primary"> Statements & Reports</Button>}
                        {/* <Dropdown overlay={exportDropdown}>
                            <Button type="primary" loading={exportLoading}>
                                <Space align='start'>
                                    <FaFileDownload size={18} /> Export Statement
                                </Space>
                            </Button>
                        </Dropdown> */}
                    </Space>
                </Col>
            </Row>

            <Row className="m-t-10">
                <Col span={24}>
                    <CustomPagination
                        loading={isLoading || isFetching}
                        onPageChange={handlePageChange}
                        total={response?.pager?.count ?? 0}
                        pageSize={limit}
                        current={currentPage}
                        onPageSizeChange={handlePageSizeChange}
                    />
                    <CustomTable
                        rowSelection={rowSelection}
                        loading={isLoading || isFetching}
                        columns={tableColumns}
                        dataSource={response?.data ?? []}
                        className='spaced-rows'
                        headerColor='green'
                        rowClassName='fs-18px regular pointer'
                        styleAllRows
                        rowKey={record => uniqueId() + record.id}
                        onChange={onChange}
                        onRow={(record) => ({
                            onClick: () => navigate(`${URLS.SpecificCardTransaction}/${record.id}`),
                        })}
                    />
                    <CustomPagination
                        loading={isLoading || isFetching}
                        onPageChange={handlePageChange}
                        total={response?.pager?.count ?? 0}
                        pageSize={limit}
                        current={currentPage}
                        onPageSizeChange={handlePageSizeChange}
                    />
                </Col>
            </Row>
        </>
    )
}

export default TransactionHistory;
