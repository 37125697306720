import wallet from "Assets/Images/wallet.svg";
import payments from "./images/payments.png";
import cards from "./images/cards.png";
import fxForwards from "Assets/Images/fx-forwards.svg";
import accounting from "./images/accounting.png";
import squareLogo from "./images/logo.png";
import URLS from "Routes/constants";
import { USER_ROLES } from "Constants/UserRoles";


//
const moduleData = [
    {
        name: 'Wallet',
        image: wallet,
        url: URLS.WalletDashboard,
        key: 'wallet',
        allowed_roles: [
            USER_ROLES.primary_admin.roleId,
            USER_ROLES.admin.roleId
        ],
    },
    {
        name: 'Payments',
        image: payments,
        url: URLS.PaymentsDashboard,
        key: 'payments',
        allowed_roles: [
            USER_ROLES.primary_admin.roleId,
            USER_ROLES.admin.roleId,
            USER_ROLES.business_user.roleId
        ],
    },
    {
        name: 'Cards',
        image: cards,
        url: URLS.CardsDashboard,
        key: 'cards',
        allowed_roles: [
            USER_ROLES.primary_admin.roleId,
            USER_ROLES.admin.roleId,
        ],
    },
    {
        name: 'Cards',
        image: cards,
        url: URLS.PersonalCardsDashboard,
        key: 'cards',
        allowed_roles: [
            USER_ROLES.business_user.roleId,
            USER_ROLES.card_user.roleId
        ],
    },
    {
        name: 'FX Forwards',
        image: fxForwards,
        url: URLS.FxForwardsDashboard,
        key: 'fx-forwards',
        allowed_roles: [
            USER_ROLES.primary_admin.roleId,
            USER_ROLES.admin.roleId,
            USER_ROLES.business_user.roleId
        ],
    },
    {
        name: 'Accounting',
        image: accounting,
        url: URLS.ExpenseTransactions,
        key: 'accounting',
        allowed_roles: [
            USER_ROLES.primary_admin.roleId,
            USER_ROLES.admin.roleId
        ],
    },
    {
        name: 'Settings',
        image: squareLogo,
        url: URLS.CompanyAccount,
        key: 'settings',
        allowed_roles: [
            USER_ROLES.primary_admin.roleId,
            USER_ROLES.admin.roleId
        ],
    },
    {
        name: 'Settings',
        image: squareLogo,
        url: URLS.PersonalProfile,
        key: 'settings',
        allowed_roles: [
            USER_ROLES.business_user.roleId,
            USER_ROLES.card_user.roleId
        ],
    }
];

//
const moduleItemsData = {
    wallet: [
        {
            label: 'Wallet Dashboard',
            key: URLS.WalletDashboard,
            allowed_roles: [
                USER_ROLES.primary_admin.roleId,
                USER_ROLES.admin.roleId
            ],
        },
        {
            label: 'Fund Wallet',
            key: URLS.FundWallet,
            allowed_roles: [
                USER_ROLES.primary_admin.roleId,
                USER_ROLES.admin.roleId
            ],
        },
        {
            label: 'Convert Balances',
            key: URLS.ConvertWalletBalances,
            allowed_roles: [
                USER_ROLES.primary_admin.roleId,
                USER_ROLES.admin.roleId
            ],
        },
        {
            label: 'Funding History',
            key: URLS.FundingHistory,
            allowed_roles: [
                USER_ROLES.primary_admin.roleId,
                USER_ROLES.admin.roleId
            ],
        }
    ],
    payments: [
        {
            label: 'Payments Dashboard',
            key: URLS.PaymentsDashboard,
            allowed_roles: [
                USER_ROLES.primary_admin.roleId,
                USER_ROLES.admin.roleId,
                USER_ROLES.business_user.roleId
            ],
        },
        {
            label: 'Recipient List',
            key: URLS.RecipientList,
            allowed_roles: [
                USER_ROLES.primary_admin.roleId,
                USER_ROLES.admin.roleId,
                USER_ROLES.business_user.roleId
            ],
        },
        {
            label: 'New Payment',
            key: URLS.NewPayment,
            allowed_roles: [
                USER_ROLES.primary_admin.roleId,
                USER_ROLES.admin.roleId,
                USER_ROLES.business_user.roleId
            ],
        },
        {
            label: 'Batch Payments',
            key: URLS.BatchPayments,
            allowed_roles: [
                USER_ROLES.primary_admin.roleId,
                USER_ROLES.admin.roleId,
                USER_ROLES.business_user.roleId
            ],
        },
        {
            label: 'Payment History',
            key: URLS.PaymentHistory,
            allowed_roles: [
                USER_ROLES.primary_admin.roleId,
                USER_ROLES.admin.roleId,
                USER_ROLES.business_user.roleId
            ],
        }
    ],
    cards: [
        {
            label: 'Cards Dashboard',
            key: URLS.CardsDashboard,
            allowed_roles: [
                USER_ROLES.primary_admin.roleId,
                USER_ROLES.admin.roleId,
            ],
        },
        {
            label: 'Cards Dashboard',
            key: URLS.PersonalCardsDashboard,
            allowed_roles: [
                USER_ROLES.business_user.roleId,
                USER_ROLES.card_user.roleId
            ],
        },
        {
            label: 'Cards',
            key: URLS.Cards,
            allowed_roles: [
                USER_ROLES.primary_admin.roleId,
                USER_ROLES.admin.roleId
            ],
        },
        {
            label: 'Transaction History',
            key: URLS.TransactionHistory,
            allowed_roles: [
                USER_ROLES.primary_admin.roleId,
                USER_ROLES.admin.roleId,
                USER_ROLES.business_user.roleId,
                USER_ROLES.card_user.roleId
            ],
        },
        {
            label: 'Bulk Card Controls',
            key: URLS.BulkCardControls,
            allowed_roles: [
                USER_ROLES.primary_admin.roleId,
                USER_ROLES.admin.roleId
            ],
        }
    ],
    "fx-forwards": [
        {
            label: 'FX Forwards Dashboard',
            key: URLS.FxForwardsDashboard,
            allowed_roles: [
                USER_ROLES.primary_admin.roleId,
                USER_ROLES.admin.roleId,
                USER_ROLES.business_user.roleId,
            ]
        }
    ],
    accounting: [
        {
            label: 'Transactions',
            key: URLS.ExpenseTransactions,
            allowed_roles: [
                USER_ROLES.primary_admin.roleId,
                USER_ROLES.admin.roleId
            ],
        },
        {
            label: 'Configuration',
            key: URLS.Configuration,
            allowed_roles: [
                USER_ROLES.primary_admin.roleId,
                USER_ROLES.admin.roleId
            ],
        }
    ],
    settings: [
        {
            label: 'Company Account',
            key: URLS.CompanyAccount,
            allowed_roles: [
                USER_ROLES.primary_admin.roleId,
                USER_ROLES.admin.roleId
            ],
        },
        {
            label: 'Personal Profile',
            key: URLS.PersonalProfile,
            allowed_roles: [
                USER_ROLES.primary_admin.roleId,
                USER_ROLES.admin.roleId,
                USER_ROLES.business_user.roleId,
                USER_ROLES.card_user.roleId
            ],
        },
        {
            label: 'Additional Services',
            key: URLS.AdditionalServices,
            allowed_roles: [
                USER_ROLES.primary_admin.roleId,
                USER_ROLES.admin.roleId
            ],
        }
    ]
};

//
const userMenuItems = [
    {
        key: 'my-profile',
        label: 'My Profile',
    },
    {
        key: 'settings',
        label: 'Settings',
    },
    {
        key: 'help',
        label: 'Help',
    },
    {
        key: 'logout',
        label: 'Logout'
    },
];

export {
    moduleItemsData,
    moduleData,
    userMenuItems
};
