import { Button, Card, Col, Row, Space, Typography } from 'antd';
import CustomTable from 'App/Components/CustomTable';
import PageDescription from 'App/Components/PageDescription';
import { useNavigate } from 'react-router-dom';
import URLS from 'Routes/constants';
import { pageContent } from './store/data';
import { useGetPaymentCutOff } from './query/useGetPaymentCutOff';
import { useMemo } from 'react';

function BankToBankDeliveryTimescales() {
    const navigate = useNavigate();
    const paymentCutoffPage = useGetPaymentCutOff();
    const htmlString = paymentCutoffPage?.data?.data?.content?.rendered;
    const rowsData = useMemo(() => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;

        const sections = tempElement.querySelectorAll(".elementor-section");
        const data = [];

        sections.forEach(section => {
            const rowData = {};

            const ul = section.querySelector("ul");
            const pTags = section.querySelectorAll(".elementor-col-25 p");

            if (ul && ul.classList.length === 0) {
                const linkElement = ul.querySelector('a');
                const link = linkElement.getAttribute('href');
                const text = linkElement.textContent.trim();
                rowData.currency = {link, text};
            }
    
            if (pTags.length) {
                const createVolopa = pTags[0].textContent.trim();
                const priority = pTags[1].textContent.trim();
                const regular = pTags[2].textContent.trim();
                rowData.createVolopaBookingBy = createVolopa;
                rowData.priority = priority;
                rowData.regular = regular;
            }
    
            if (Object.keys(rowData).length > 0) {
                data.push(rowData);
            }            
        });
        return data;
    }, [htmlString]);
    
    const columns = [
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            render: text => <Space size={4}>
                <Typography.Link style={{ color: 'inherit', textDecoration: 'underline'}} href={text?.link}>{text?.text}</Typography.Link>
            </Space>
        },
        {
            title: 'Create Volopa Booking By',
            dataIndex: 'createVolopaBookingBy',
            key: 'createVolopaBookingBy'
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority'
        },
        {
            title: 'Regular',
            dataIndex: 'regular',
            key: 'regular'
        }
    ]
    return (
        <>
            <Row>
                <Col span={24}>
                    <PageDescription title='Bank to Bank Delivery Timescales' />
                </Col>
            </Row>
            <Row className='m-t-10' justify='end'>
                <Col>
                    <Button type='primary' onClick={() => navigate(URLS.UserGuides)}>Return to User Guides</Button>
                </Col>
            </Row>
            <Row className='m-t-20' gutter={[12, 12]}>
                {pageContent && pageContent.length > 0 && pageContent.map((item, key) => (
                    <Col span={24} key={key}>
                        <PageDescription
                            title={item.title}
                            text={item.text}
                            defaultStyle={false}
                        />
                    </Col>
                ))}
            </Row>
            <Row className='m-t-20'>
                <Col span={12}>
                    <Card hoverable className='b-g'>
                        <Row justify='end'>
                            <Col span={15}>
                                <Typography.Text className='dark-green muli semi-bold fs-18px'>Number of Days after Settlement</Typography.Text>
                            </Col>
                        </Row>
                        <CustomTable
                            columns={columns}
                            dataSource={rowsData}
                            headerColor='green'
                            headerFont='muli'
                            loading={paymentCutoffPage?.isLoading}
                            rowType='filled' />
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default BankToBankDeliveryTimescales;
