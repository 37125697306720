import { useNavbarState } from "App/Components/NavBar/store";
import { APIROUTES } from "Constants/ApiRoutes";
import { useApiRequest } from "Hooks/API";
import URLS from "Routes/constants";
import { accountingFormat } from "Utils";
import { Button, Card, Col, Row, Space, Typography } from "antd";
import { useState } from "react";
import { BsFillCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function AttachReceipt({ acknowledged, alert_json_object, id }) {
  const title = alert_json_object?.title;
  const currency = alert_json_object?.ccy_code?.data;
  const amount = alert_json_object?.amount?.data;
  const merchantName = alert_json_object?.merchant_name?.data;
  const [paymentId] = useState(alert_json_object?.transaction_id?.data);
  const apiRequest = useApiRequest();
  const setNavbarState = useNavbarState(state => state.setState);
  const navigate = useNavigate();

  const handleAction = () => {
    apiRequest(`${APIROUTES.put.notifications}${id}`, 'PUT', { acknowledged: 1 });
    setNavbarState({ notificationsOpen: false });
    navigate(`${URLS.SpecificCardTransaction}/${paymentId}`)
  }
  return (
    <Card className='bg-extra-light-grey'>
      <Row className={acknowledged ? 'm-l-20' : ''}>
        <Col flex='auto'>
          <Space size={10}>
            {!acknowledged && <BsFillCircleFill size={10} className='light-green' />}
            <Typography.Text className="fs-20px regular">{title}</Typography.Text>
          </Space>
        </Col>
      </Row>
      <Row className="m-t-5 m-l-20">
        <Col flex='auto'>
          <Typography.Text className="fs-20px regular">
            Your transaction for <span className='bold'>{currency} {accountingFormat(amount)}</span> at <span className="bold">{merchantName} </span>
            has been authorised.
          </Typography.Text>
        </Col>
      </Row>
      <Row className="m-t-5 m-l-20">
        <Col flex='auto'>
          <Typography.Text className="fs-20px regular">
            Dont forget to <span className='bold'>attach a receipt!</span>
          </Typography.Text>
        </Col>
      </Row>
      <Row justify='end' className="m-t-10">
        <Col>
          <Button type='primary' onClick={handleAction}>Attach Receipt</Button>
        </Col>
      </Row>
    </Card>
  );
}

export default AttachReceipt;