import { parseNumber } from "@brightspace-ui/intl";
import { TCC_CROSS_BORDER_PAYMENT_PURPOSE_CODES } from "Constants/Common";

export const noop = () => { };

export const isBrowser = typeof window !== 'undefined';

export const isNavigator = typeof navigator !== 'undefined';

export const firstAndLast4CardNumber = (cardNumber) => `${cardNumber.slice(0, 4)} **** **** ${cardNumber.substr(-4)}`;

export const pluralize = (num, singular, plural) => num === 1 ? singular : plural;

/**
 * This function to used only for displaying values.
 * Do not use for calculations.
 * When using InputNumber antd or
 * input type="number" component use precision attr (precision={2})
 *
 * @param {string|number} value
 * @param {string|string[]} locales
 * @param {number} decimals
 * @returns number
 */
export const accountingFormat = (value, decimals = 2, locales = 'en-US') => {
	if (value === undefined || value === null || value === '' || (typeof value !== 'number' && typeof value !== 'string') || isNaN(value) || !/^(?!\s*$)/.test(value))
		return value;

	const config = {
		useGrouping: true, // commas for thousands
	}

	config.maximumFractionDigits = decimals || 2
	if (decimals) {
		config.minimumFractionDigits = decimals
	}
	return Number(value).toLocaleString(locales, config);
}

export const numberFormat = (value) => {

	const val = String(value)?.replace(/[^\d.-]+|(?<=\d)-|(?<!\d)\.|\.(?!\d)/g, ""); // sanitize value

	return typeof val === 'string' && val !== '' ? parseNumber(val) : val === undefined || val === null || val === '' ? undefined : +val;
}

//
export const urlObjectCreator = (object) => {
	if (!object) return;

	const urlCreator = window?.URL || window?.webkitURL;

	return urlCreator.createObjectURL(object);
}


/**
 * Adds a leading 0 to card expiry date
 * when response format is 9/2023
 *
 * @param {string} expiryDate 9/2023
 * @returns  string
 */
export const zeroPadStartExpiryDate = (expiryDate) => {
	if (!expiryDate) {
		return null;
	}

	const items = expiryDate?.split('/');
	items[0] = zeroPadStart(items[0]);
	return items.join('/');
}

/**
 * Adds a leading 0 to numbers < 10
 * (9 -> 09)
 * @param {string|number}
 * @returns  string
 */
export const zeroPadStart = (val) => {
	if (!val) {
		return null;
	}

	return String(val).padStart(2, 0);
}

// Get accessToken expiry time and subtract 1 minute
/**
 *
 * @param {string|number} expires
 * @returns Date
 */
export const makeAccessTokenExpiryTime = (expires) => {
	let date = new Date();
	date.setSeconds(date.getSeconds() + parseInt(expires));
	date.setMinutes(date.getMinutes() - 1);

	return date;
}

// Getting the localeStorage this way does not
// trigger component re-render
// so use accordingly
export const getLocaleStore = (key) => {
	if (!key) {
		return null;
	}

	return JSON.parse(localStorage.getItem(key));
}

//
export const setLocaleStore = (key, data) => {
	if (!key) {
		return;
	}

	localStorage.setItem(key, JSON.stringify(data));
}

//
export const getRefreshTokenStore = () => {
	return localStorage.getItem('_refresh_token');
}

//
export const setRefreshTokenStore = (token) => {
	return localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_REFRESH_TOKEN_KEY, token);
}

//
export const getTokenClientId = () => {
	return localStorage.getItem('_tok_client_id');
}

/**
 * checkIfCrossBorderPurposeRecipient
 * @param {object} recipient Recipient (beneficiary)
 * @param {string} recipient.bank_country
 * @param {string} recipient.currency
 * @param {string} country Recipient (beneficiary) bank country
 * @param {string} ccy Recipient (beneficiary) bank ccy
 * @returns object|null
 */
export const checkIfCrossBorderPurposeRecipient = (recipient = null, country = null, ccy = null) => {
	if(recipient) {
		return TCC_CROSS_BORDER_PAYMENT_PURPOSE_CODES?.[`${recipient?.bank_country}_${recipient?.currency}`];
	}

	return TCC_CROSS_BORDER_PAYMENT_PURPOSE_CODES?.[`${country}_${ccy}`];
}
