import { Grid, Button, Checkbox, Col, Image, Row, Select, Space, Tooltip, Typography, Pagination, message, Spin, notification } from "antd";
import PageDescription from "App/Components/PageDescription";
import { FaSyncAlt } from "react-icons/fa";
import moment from "moment";
import { FLAGS } from "Constants/Images";
import { AiFillExclamationCircle, AiOutlinePaperClip, AiFillCheckCircle, AiOutlineSync } from "react-icons/ai"
import CustomTable from "App/Components/CustomTable";
import { colors } from "Constants/Style";
import { useTransactionsExpensesState } from "App/Pages/Accounting/Transactions/Expenses/store";
import {
    useAccountingExpensesQuery,
    useSyncExpensesMutation,
    useOverrideCodatCatIdMutation,
    useOverrideCodatTaxIdMutation
} from "App/Pages/Accounting/Transactions/Expenses/query";
import Spinner from "App/Components/Spinner";
import { TransactionsPageNav } from "App/Pages/Accounting/Transactions/components";
import { useTaxRatesQuery, useCodatAccountCategoriesQuery, useBankAccountsQuery } from "App/Pages/Accounting/Configuration/query";
import { useConfigurationState } from "App/Pages/Accounting/Configuration/store";
import { firstAndLast4CardNumber, accountingFormat, pluralize } from "Utils";
import { useQueryClient } from "@tanstack/react-query";
import { FORMATS } from "Constants/Formats";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import URLS from "Routes/constants";
import { useTrackingCategoryQuery } from "../../Configuration/query/useTrackingCategoryQuery";
import { useOverrideCodatTrackingCatIdMutation } from "./query/useOverrideCodatTrackingCatIdMutation";
import { useUnsyncExpensesCountQuery } from "./query/useUnsyncExpensesCountQuery";
import { CODAT_ACCOUNTING_PLATFORMS } from "Constants/Common";

const { useBreakpoint } = Grid;

const paginationStyles = {
    marginTop: '30px',
    marginBottom: '20px',
    padding: '8px 18px',
    background: '#eee',
    borderRadius: '8px'
};

const accountOrTaxCodeOverridenMessage = (msg) => {
    message.success({
        content: msg,
        duration: 1,
    })
}

function AccountingExpenseTransactions() {
    const breakpoint = useBreakpoint();

    const accountingLogo = useConfigurationState(state => state.accountingLogo);
    const platform_type = useConfigurationState(state => state.platform_type);
    const codatBankAccountId = useConfigurationState(state => state.codatBankAccountId);
    const loading = useTransactionsExpensesState(state => state.loading);
    const syncQueue = useTransactionsExpensesState(state => state.syncQueue);
    const syncAllChecked = useTransactionsExpensesState(state => state.syncAllChecked);
    const currentPage = useTransactionsExpensesState(state => state.page);
    const totalPages = useTransactionsExpensesState(state => state.totalPages);
    const limit = useTransactionsExpensesState(state => state.limit);
    const syncStatus = useTransactionsExpensesState(state => state.syncStatus);
    const isSyncCheckboxEnabled = useTransactionsExpensesState(state => state.isSyncCheckboxEnabled);
    const isSyncing = useTransactionsExpensesState(state => state.isSyncing);
    const isSyncingDirectCost = useTransactionsExpensesState(state => state.isSyncingDirectCost);
    const isSyncingBillPayment = useTransactionsExpensesState(state => state.isSyncingBillPayment);
    const accountOrTaxCodeChangedItems = useTransactionsExpensesState(state => state.accountOrTaxCodeChangedItems);
    const setState = useTransactionsExpensesState(state => state.setState);
    const expensesQuery = useAccountingExpensesQuery();
    const { data: unsyncExpenseCount, isLoading: expenseCountLoading } = useUnsyncExpensesCountQuery();
    const { data: codatAccountCategories } = useCodatAccountCategoriesQuery();
    const { data: codatTaxRates } = useTaxRatesQuery();
    const { data: codatTrackingCategory } = useTrackingCategoryQuery();
    const syncExpensesMutation = useSyncExpensesMutation(payload => payload);
    const overrideCodatCatIdMutation = useOverrideCodatCatIdMutation(payload => payload);
    const overrideCodatTaxIdMutation = useOverrideCodatTaxIdMutation(payload => payload);
    const overrideCodatTrackingCatIdMutation = useOverrideCodatTrackingCatIdMutation(payload => payload);
    const bankAccountsQuery = useBankAccountsQuery();
    const queryClient = useQueryClient();

    const navigate = useNavigate();

    const expensesQueryLoading = expensesQuery.isLoading || expensesQuery.isFetching || expensesQuery.isRefetching;

    const syncStatuses = [
        {
            id: 1,
            name: 'View Synced Transactions',
            val: '1',
        },
        {
            id: 235,
            val: '2,3,5',
            name: `${unsyncExpenseCount?.pager?.count ?? 0} Expenses to Sync`,
        },
    ];

    useEffect(() => {
        queryClient.invalidateQueries({ queryKey: ['_accounting_company_map'], exact: true });
        return () => {
            setState({ page: 1, limit: 10, })
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!codatBankAccountId) {
            if (syncStatus === 3) {
                notification.info({ message: 'Please select the account you wish to sync your Volopa transactions with.', placement: 'top' });
            }

            navigate(URLS.Configuration);
        }
        // eslint-disable-next-line
    }, [codatBankAccountId]);

    useEffect(() => {
        if (!!bankAccountsQuery.data?.response?.error) {
            if (syncStatus === 3) {
                notification.info({ message: 'Please wait while accounts are being synchronized.', placement: 'top' });
            }

            navigate(URLS.Configuration);
        }
        // eslint-disable-next-line
    }, [bankAccountsQuery.data]);

    //
    const validateItemsForSyncQueue = () => {
        return expensesQuery.data.data.filter(item => {
            let catId = item.override_codat_category_id || item.account_code;
            let taxId = item.override_codat_tax_id || item.tax_code;

            // If item is pending or does not have a cat or tax code
            if (item.synch_id === 5 || (!catId || !taxId)) {
                return false;
            }

            // If item sync is errored and user has not changed cat or tax code dropdown
            if (item.synch_id === 2 && !accountOrTaxCodeChangedItems.includes(item.id)) {
                return false;
            }

            // If item is not synced or is sync error and user has changed cat or tax code dropdown
            return true;
        }).map(item => item.id);
    }

    //
    const handleAddAllToSyncQueue = (evt) => {
        if (!evt.target.checked) {
            setState({ syncQueue: [], syncAllChecked: evt.target.checked });
            return;
        }

        const validatedItems = validateItemsForSyncQueue();

        if (!validatedItems || !validatedItems.length) {
            setState({ syncQueue: [], syncAllChecked: false });
            return;
        }

        setState({
            syncAllChecked: evt.target.checked,
            syncQueue: validatedItems,
        });
    }

    //
    const handleAddItemToSyncQueue = (evt, id) => {
        if (!evt.target.checked) {
            setState({ syncQueue: syncQueue.filter(item => item !== id) });
            return;
        }

        let item = syncQueue.find(item => item === id);

        if (!item) {
            setState({ syncQueue: [...syncQueue, id] });
        }
    }

    //
    const handleSyncSelected = async (sync_type) => {
        if (!syncQueue.length || !syncQueue) {
            return;
        }
        if (sync_type === 1) {
            setState({ isSyncingDirectCost: true });
        } else if (sync_type === 2) {
            setState({ isSyncingBillPayment: true });
        } else {
            setState({ isSyncing: true });
        }
        let mutations = [];
        syncQueue.forEach(id => {
            if (platform_type === 'Dynamics 365 Business Central') {
                mutations.push(
                    syncExpensesMutation.mutateAsync({ expenseId: id, sync_type })
                )
            } else {
                mutations.push(
                    syncExpensesMutation.mutateAsync({ expenseId: id })
                )
            }
        });

        const res = await Promise.all(mutations);
        const errors = res?.filter(val => !!val?.response?.data?.error?.message)?.map(item => item?.response?.data?.error?.message)
        const uniqueErrors = [];
        errors?.forEach(error => uniqueErrors?.indexOf(error) === -1 && uniqueErrors.push(error))
        if (uniqueErrors?.length > 0) {
            notification.error({
                message: "Error",
                description: <Space direction="vertical" size={0}>{uniqueErrors?.map(error => error)}</Space>,
                placement: 'top'
            })
        }
        if (sync_type === 1) {
            setState({ isSyncingDirectCost: false });
        } else if (sync_type === 2) {
            setState({ isSyncingBillPayment: false });
        } else {
            setState({ isSyncing: false });
        }
        setState({ syncQueue: [] })
    }

    //
    const handlePageChange = (page, pageSize) => {
        setState({
            page,
            syncQueue: [],
            syncAllChecked: false,
        });
    }

    //
    const handlePageSizeChange = (current, size) => {
        setState({
            limit: size,
            page: current,
        });
    }

    //
    const handleItemAccountCodeChange = async (catId, item) => {
        let currentCatId = item.override_codat_category_id || item.account_code;
        if (catId === currentCatId) {
            return;
        }

        try {
            const res = await overrideCodatCatIdMutation.mutateAsync({ catId, itemId: item.id });
            if (res?.response?.data?.error) {
                notification.error({ message: 'Something went wrong.', placement: 'top' });
                return;
            }
            queryClient.invalidateQueries({ queryKey: ['_get_accounting_transactions_expenses_notsynced'], exact: false });
            setState({ accountOrTaxCodeChangedItems: [...accountOrTaxCodeChangedItems, item.id] });
            accountOrTaxCodeOverridenMessage('Account code saved!');
        } catch (err) {
            notification.error({ message: 'Not all changes where saved. Please check and try again.', placement: 'top' });
        }
    }

    //
    const handleItemTaxCodeChange = async (taxId, item) => {
        let currentTaxId = item.override_codat_tax_id || item.tax_code;
        if (taxId === currentTaxId) {
            return;
        }

        try {
            const res = await overrideCodatTaxIdMutation.mutateAsync({ taxId, itemId: item.id });
            if (res?.response?.data?.error) {
                notification.error({ message: 'Something went wrong.', placement: 'top' });
                return;
            }
            queryClient.invalidateQueries({ queryKey: ['_get_accounting_transactions_expenses_notsynced'], exact: false });
            setState({ accountOrTaxCodeChangedItems: [...accountOrTaxCodeChangedItems, item.id] });
            accountOrTaxCodeOverridenMessage('Tax code saved!');
        } catch (err) {
            notification.error({ message: 'Not all changes where saved. Please check and try again.', placement: 'top' });
        }
    }

    //
    const handleItemTrackingCategoryChange = async (catId, item) => {
        let currentCatId = item.override_codat_tracking_category_id || item.tracking_category;
        if (catId === currentCatId) {
            return;
        }

        try {
            const res = await overrideCodatTrackingCatIdMutation.mutateAsync({ trackingId: catId, itemId: item.id });
            if (res?.response?.data?.error) {
                notification.error({ message: 'Something went wrong.', placement: 'top' });
                return;
            }
            queryClient.invalidateQueries({ queryKey: ['_get_accounting_transactions_expenses_notsynced'], exact: false });
            setState({ accountOrTaxCodeChangedItems: [...accountOrTaxCodeChangedItems, item.id] });
            accountOrTaxCodeOverridenMessage('Tracking Category saved!');
        } catch (err) {
            notification.error({ message: 'Not all changes where saved. Please check and try again.', placement: 'top' });
        }
    }

    //
    const TablePagination = () => {
        const placeholderStyles = {
            marginTop: '30px',
            marginBottom: '20px',
            padding: '8px 18px',
            background: 'rgb(238, 238, 238)',
            borderRadius: '8px',
            height: '48px',
            display: 'flex',
            alignItems: 'center',
        }

        return <>
            {expensesQueryLoading
                ? <div style={placeholderStyles}>
                    <Spin size="small" />
                </div>
                : <>
                    {expensesQuery?.data?.pager?.count < 11
                        ? <div style={placeholderStyles}>
                            {expensesQuery?.data?.pager?.count < 1 ? `No expenses ${syncStatus !== '1' ? 'to be' : ''} synced.` : `${expensesQuery?.data?.pager?.count} ${pluralize(expensesQuery?.data?.pager?.count, 'expense', 'expenses')} ${syncStatus !== '1' ? 'to be' : ''} synced.`}
                        </div>
                        : <Pagination
                            disabled={totalPages < 11}
                            style={paginationStyles}
                            onChange={(page, pageSize) => handlePageChange(page, pageSize)}
                            onShowSizeChange={(current, size) => handlePageSizeChange(current, size)}
                            pageSize={limit}
                            total={totalPages}
                            current={currentPage}
                            showSizeChanger
                        />
                    }
                </>
            }
        </>
    }
    //
    const columns = [
        {
            title: 'Date',
            dataIndex: ['transaction_time'],
            key: 'txn_date',
            sorter: (a, b) => a.transaction_time && b.transaction_time && moment(a.transaction_time).diff(moment(b.transaction_time)),
            render: (text, record, index) => moment(text).format('DD-MM-YYYY')
        },
        {
            title: 'Card',
            dataIndex: ['transaction', 'prepaid_card'],
            key: 'card',
            render: (text, record, index) => record ?
                <Space direction="vertical">
                    <div>
                        {text?.card_holder?.title} {' '}
                        {text?.card_holder?.first_name} {' '}
                        {text?.card_holder?.last_name}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {firstAndLast4CardNumber(text.card_number)}
                    </div>
                </Space> : ''
        },
        {
            title: 'Description',
            dataIndex: ['transaction', 'description'],
            key: 'txn_description',
            sorter: (a, b) => a?.transaction?.description && b?.transaction?.description && a?.transaction?.description?.localeCompare(b?.transaction?.description),
            render: (text, record, index) => `${text.slice(0, 42)}...`
        },
        {
            title: 'Account Code',
            dataIndex: 'override_codat_category_id',
            key: 'override_codat_category_id',
            render: (text, record, index) => <Select
                defaultValue={text || record.account_code}
                disabled={record.synch_id === 1 || record.synch_id === 5}
                placeholder="Select account code"
                onChange={(val) => handleItemAccountCodeChange(val, record)}
                className="dark-green select-b-g full-percent-width"
            >
                {codatAccountCategories?.response?.results?.length && codatAccountCategories?.response?.results?.map(cat =>
                    <Select.Option key={cat.id} value={cat.id}>
                        {`${cat.name} (${cat.nominalCode})`}
                    </Select.Option>
                )}
            </Select>
        },
        {
            title: 'VAT Code',
            dataIndex: 'override_codat_tax_id',
            key: 'override_codat_tax_id',
            render: (text, record) => <Select
                defaultValue={text || record.tax_code}
                placeholder="Select VAT code"
                onChange={(val) => handleItemTaxCodeChange(val, record)}
                disabled={record.synch_id === 1 || record.synch_id === 5}
                className="dark-green select-b-g full-percent-width"
            >
                {codatTaxRates?.response?.results?.length && codatTaxRates?.response?.results?.map(tax =>
                    <Select.Option key={tax.id} value={tax.id}>
                        {tax.name}
                    </Select.Option>
                )}
            </Select>
        },
        {
            title: 'Tracking Category',
            dataIndex: 'override_codat_tracking_category_id',
            key: 'override_codat_tracking_category_id',
            render: (text, record) => <Select
                defaultValue={text || record.tax_code}
                placeholder="Select Tracking Category"
                onChange={(val) => handleItemTrackingCategoryChange(val, record)}
                disabled={record.synch_id === 1 || record.synch_id === 5}
                className="dark-green select-b-g full-percent-width"
            >
                {/* -- MSDyn ------ */}
                {platform_type === CODAT_ACCOUNTING_PLATFORMS.msdyn ? (
                    codatTrackingCategory?.response?.results?.filter(item => item.status === 'Active' && item?.hasChildren !== true && !item?.name?.toLowerCase()?.includes('total'))?.map(track => (
                        <Select.Option key={track.id} value={track.id}>
                            {track.name}
                        </Select.Option>
                    ))
                ) : null}

                {/* -- QB ------ */}
                {/* On dev/sandbox Codat uses diff paltform_type name than live/UAT */}
                {(platform_type === CODAT_ACCOUNTING_PLATFORMS.qb || platform_type === CODAT_ACCOUNTING_PLATFORMS.qb_sandbox) ? (
                    codatTrackingCategory?.response?.results?.filter(item => !!item?.parentId && item.status === 'Active')?.map(track => (
                        <Select.Option key={track.id} value={track.id}>
                            {track.name}
                        </Select.Option>
                    ))
                ) : null}

                {/* -- Xero ------ */}
                {platform_type === CODAT_ACCOUNTING_PLATFORMS.xero ? (
                    codatTrackingCategory?.response?.results?.filter(item => item.status === 'Active' && item?.hasChildren !== true)?.map(track => (
                        <Select.Option key={track.id} value={track.id}>
                            {track.name}
                        </Select.Option>
                    ))
                ) : null}
            </Select>
        },
        // {
        //     title: 'Attachments',
        //     dataIndex: 'attachments',
        //     key: 'attachments',
        //     align: 'center',
        //     render: (text) => text ? <AiOutlinePaperClip size={24} /> : <AiOutlinePaperClip size={24} />
        // },
        {
            title: 'Currency',
            dataIndex: ['transaction', 'currency', 'currency'],
            key: 'currency',
            sorter: (a, b) => {
                if (a && b) {
                    const aString = String(a);
                    const bString = String(b);
                    return aString.localeCompare(bString);
                }
                return 0;
            },
            render: (text, record, index) => <Space size={12}>
                {FLAGS?.[text] ? <Image src={FLAGS[text]} alt={text} preview={false} width={30} /> : null}
                {text}
            </Space>
        },
        {
            title: 'Amount',
            dataIndex: ['transaction', 'amount'],
            key: 'txn_amount',
            sorter: (a, b) => a?.transaction?.amount - b?.transaction?.amount,
            render: (text, record) => <Typography.Text type={record.transaction.debit_credit === 1 ? 'success' : 'danger'}>
                {record.transaction.debit_credit === 1 ? '+' : '-'}{accountingFormat(text)}
            </Typography.Text>
        },
        {
            title: <Space align='center'>
                {syncStatus !== "1"
                    ? <> Select All
                        <Checkbox checked={syncAllChecked} onChange={handleAddAllToSyncQueue} />
                    </>
                    : <>Status</>
                }
            </Space>,
            dataIndex: 'synch_id',
            key: 'synch_id',
            align: 'center',
            render: (value, record) => {
                if (value === 1) {
                    return <>
                        <Space direction="vertical">
                            <AiFillCheckCircle size={24} color={colors.success} />
                            {platform_type === 'Dynamics 365 Business Central' && <Typography style={{ fontSize: 12 }}>{record?.sync_type_id === 2 ? "Bill" : record?.sync_type_id === 1 ? "Direct Cost" : ""}</Typography>}
                        </Space>
                    </>

                } else if (value === 2) {
                    let _catId = record.override_codat_category_id || record.account_code;
                    let _taxId = record.override_codat_tax_id || record.tax_code;
                    return <>
                        {isSyncCheckboxEnabled(record.id)
                            ? <Checkbox disabled={!_catId || !_taxId} checked={syncQueue.includes(record.id)} onChange={(evt) => handleAddItemToSyncQueue(evt, record.id)} />
                            : <Tooltip title="Sync error" color={colors.danger} >
                                <AiFillExclamationCircle style={{ transform: 'translateY(5px)' }} size={24} color={colors.danger} />
                            </Tooltip>
                        }
                    </>
                } else if (value === 5) {
                    return <Tooltip title="Pending" color={colors.lightGreen} >
                        <AiOutlineSync size={24} style={{ transform: 'translateY(5px)' }} color={colors.lightGreen} />
                    </Tooltip>
                } else {
                    let _catId = record.override_codat_category_id || record.account_code;
                    let _taxId = record.override_codat_tax_id || record.tax_code;
                    return <Space align='center'>
                        <Checkbox disabled={!_catId || !_taxId} checked={syncQueue.includes(record.id)} onChange={(evt) => handleAddItemToSyncQueue(evt, record.id)} />
                    </Space>
                }
            }
        }
    ];

    // eslint-disable-next-line
    const _columns = {
        expenseInformation: [
            {
                title: 'Timestamp',
                dataIndex: 'timestamp',
                key: 'timestamp',
                sorter: (a, b) => a.timestamp && b.timestamp && moment(a.timestamp, 'DD-MM-YYYY').diff(moment(b.timestamp, 'DD-MM-YYYY'))
            },
            {
                title: 'Card',
                dataIndex: 'card',
                key: 'card',
                render: (text) => text ?
                    <Space direction="vertical">
                        {text.name}
                        {text.number}
                    </Space> :
                    ''
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                sorter: (a, b) => a.description && b.description && a.description.localeCompare(b.description)
            },
            {
                title: 'Account Code',
                dataIndex: 'accountCode',
                key: 'accountCode',
            },
            {
                title: 'Attachments',
                dataIndex: 'attachments',
                key: 'attachments',
                align: 'center',
                render: (text) => text ? <AiOutlinePaperClip size={24} /> : ''
            },
            {
                title: 'Card Currency',
                dataIndex: 'cardCurrency',
                key: 'cardCurrency',
                sorter: (a, b) => (a.cardCurrency && b.cardCurrency) && a.cardCurrency.localeCompare(b.cardCurrency),
                render: (text) => (
                    FLAGS[text] ?
                        <Space size={12}>
                            <Image src={FLAGS[text]} alt={text} preview={false} width={30} />
                            {text}
                        </Space> :
                        text
                )
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                sorter: (a, b) => a.amount - b.amount
            },
            {
                title: <Space align='center'>Select All <Checkbox /></Space>,
                dataIndex: 'selectAll',
                key: 'selectAll',
                align: 'center',
                render: (text) => (
                    text && text.error ?
                        <Tooltip title={text.error} color={colors.danger} >
                            <AiFillExclamationCircle size={24} color={colors.danger} />
                        </Tooltip> :
                        <Checkbox />
                )
            }
        ],
        bankFeeds: [
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                render: text => moment(text, 'DD-MM-YYYY').format(FORMATS.date),
                sorter: (a, b) => a.date && b.date && moment(a.date, 'DD-MM-YYYY').diff(moment(b.date, 'DD-MM-YYYY'))
            },
            {
                title: 'Transacrion ID',
                dataIndex: 'transactionId',
                key: 'transactionId',
                sorter: (a, b) => a.transactionId && b.transactionId && a.transactionId - b.transactionId
            },
            {
                title: 'Card',
                dataIndex: 'card',
                key: 'card',
                sorter: (a, b) => a.card && b.card && a.card.localeCompare(b.card)
            },
            {
                title: 'Card Currency',
                dataIndex: 'cardCurrency',
                key: 'cardCurrency',
                sorter: (a, b) => (a.cardCurrency && b.cardCurrency) && a.cardCurrency.localeCompare(b.cardCurrency),
                render: text =>
                    <Space size={12}>
                        {FLAGS[text] ? <Image src={FLAGS[text]} alt={text} preview={false} width={30} /> : ''}
                        {text}
                    </Space>
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                sorter: (a, b) => a.amount && b.amount && a.amount - b.amount,
                render: text => <Typography.Text type={text >= 0 ? 'success' : 'danger'}>
                    {(text > 0 && '+') + text}
                </Typography.Text>
            },
            {
                title: <Space align='center'>Select All <Checkbox /></Space>,
                dataIndex: 'selectAll',
                key: 'selectAll',
                align: 'center',
                render: () => <Checkbox />
            }
        ]
    };

    // eslint-disable-next-line
    const _data = {
        expenseInformation: [
            {
                key: 0,
                timestamp: '01-03-2022',
                card: {
                    name: 'Mr. Murray Bagshaw',
                    number: '52627 **** **** 9303'
                },
                description: 'Linkedin Transactions',
                accountCode: 'Marketing',
                attachments: true,
                cardCurrency: 'GBP',
                amount: 124
            },
            {
                key: 1,
                timestamp: '02-03-2022',
                card: {
                    name: 'Mr. Murray Bagshaw',
                    number: '52627 **** **** 9303'
                },
                description: 'Linkedin Transactions',
                accountCode: 'Marketing',
                attachments: true,
                cardCurrency: 'GBP',
                amount: 125
            },
            {
                key: 2,
                timestamp: '02-03-2022',
                card: {
                    name: 'Mr. Murray Bagshaw',
                    number: '52627 **** **** 9303'
                },
                description: 'Linkedin Transactions',
                accountCode: 'Marketing',
                attachments: true,
                cardCurrency: 'GBP',
                amount: 125,
                selectAll: {
                    error: 'No Account Code Selected'
                }
            },
            {
                key: 3,
                timestamp: '02-03-2022',
                card: {
                    name: 'Mr. Murray Bagshaw',
                    number: '52627 **** **** 9303'
                },
                description: 'Linkedin Transactions',
                accountCode: 'Marketing',
                attachments: true,
                cardCurrency: 'GBP',
                amount: 125
            }
        ],
        bankFeeds: [
            {
                key: 0,
                date: '01-03-2022',
                transactionId: 791104242,
                card: '52627 **** **** 9303',
                cardCurrency: 'GBP',
                amount: -100
            },
            {
                key: 1,
                date: '01-03-2022',
                transactionId: 461104111,
                card: '52627 **** **** 9303',
                cardCurrency: 'GBP',
                amount: -250
            },
            {
                key: 2,
                date: '01-03-2022',
                transactionId: '061177801',
                card: '52627 **** **** 9303',
                cardCurrency: 'GBP',
                amount: -195
            },
            {
                key: 3,
                date: '01-03-2022',
                transactionId: 661105531,
                card: '52627 **** **** 9303',
                cardCurrency: 'GBP',
                amount: -109
            },
            {
                key: 4,
                date: '01-03-2022',
                transactionId: 5854104242,
                card: 'Company Wallet',
                cardCurrency: 'GBP',
                amount: 200
            }
        ]
    };

    //
    const handleSyncStatusChange = (val) => {
        setState({ syncStatus: val, page: 1 });
        let syncStatusKey = val === "1" ? 'synced' : 'notsynced';
        queryClient.invalidateQueries({ queryKey: [`_get_accounting_transactions_expenses_${syncStatusKey}`], exact: false });
    }

    const handleTableChange = (_pagination, _filter, sorter) => {
        setState({ orderByField: sorter?.order ? `${sorter?.columnKey},${sorter?.order === 'ascend' ? 'asc' : 'desc'}` : null })
    }

    //
    return (
        <>

            {loading || expenseCountLoading ? <Spinner /> : (<>
                {/* Header ================== */}
                <Row>
                    <Col span={24}>
                        <PageDescription title="Review & Sync Your Latest Transactions" text='-' />
                    </Col>
                </Row>

                {/* Sync status select ================== */}
                <Row className="m-t-30" justify="space-between">
                    <Col>
                        <Select
                            defaultValue={syncStatus}
                            placeholder="Select sync status"
                            onChange={(val) => handleSyncStatusChange(val)}
                            className="dark-green select-b-g full-percent-width"
                        >
                            {syncStatuses.map(stat =>
                                <Select.Option key={stat.id} value={stat.val}>
                                    {stat.name}
                                </Select.Option>
                            )}
                        </Select>
                    </Col>
                    <Col style={{ marginTop: breakpoint.xs ? '12px' : 0 }}>
                        <Image src={accountingLogo} preview={false} style={{ width: 80 }} />
                    </Col>
                </Row>

                {/* Transactions nav ================== */}
                <Row className="m-t-30" justify="space-between">
                    <Col>
                        <TransactionsPageNav />
                    </Col>

                    <Col style={{ marginTop: breakpoint.xs ? '12px' : 0 }}>
                        {platform_type !== 'Dynamics 365 Business Central' ? <Button
                            disabled={syncStatus === "1" || !syncQueue.length}
                            type="primary"
                            size="large"
                            onClick={handleSyncSelected}
                            loading={isSyncing}
                        >
                            <Space align='start'>
                                <FaSyncAlt style={{ marginBottom: '-3px' }} />
                                Sync Selected
                            </Space>
                        </Button> :
                            <Space direction="horizontal">
                                <Button
                                    disabled={syncStatus === "1" || !syncQueue.length || isSyncingBillPayment}
                                    type="primary"
                                    size="large"
                                    onClick={() => handleSyncSelected(1)}
                                    loading={isSyncingDirectCost}
                                >
                                    <Space align='start'>
                                        <FaSyncAlt style={{ marginBottom: '-3px' }} />
                                        Sync Selected as Direct Costs
                                    </Space>
                                </Button>
                                <Button
                                    disabled={syncStatus === "1" || !syncQueue.length || isSyncingDirectCost}
                                    type="primary"
                                    size="large"
                                    onClick={() => handleSyncSelected(2)}
                                    loading={isSyncingBillPayment}
                                >
                                    <Space align='start'>
                                        <FaSyncAlt style={{ marginBottom: '-3px' }} />
                                        Sync Selected as Bill Payments
                                    </Space>
                                </Button>
                            </Space>
                        }
                    </Col>
                </Row>

                {/* Table list ================== */}
                <Row className="m-t-10">
                    <Col span={24}>
                        <TablePagination />
                        <CustomTable
                            rowKey="id"
                            loading={expensesQueryLoading}
                            columns={columns}
                            dataSource={expensesQuery.data?.data}
                            headerColor="green"
                            onChange={handleTableChange}
                            styleAllRows
                            className="spaced-rows"
                        />
                        {totalPages > 10 && <TablePagination />}
                    </Col>
                </Row>
            </>)}
        </>
    );
}

export default AccountingExpenseTransactions;
