/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Image, Radio, Row, Select, Space, Typography, Modal, notification, Card, Input, Checkbox, Tooltip, Spin } from "antd";
import { FaInfoCircle } from 'react-icons/fa';
import SwitchWithText from "App/Components/SwitchWithText";
import Spinner from "App/Components/Spinner";
import { useEffect, useRef } from "react";
import AccountingCategoryMapping from "App/Components/AccountingCategoryMapping";
import {
    useBillPaymentBankAccountsQuery,
    useBankAccountsQuery,
    useDeleteAccountingConfigMutation,
    useAccountingConfigurationQuery,
    useAccountingConfigMutation,
} from "App/Pages/Accounting/Configuration/query";
import FullScreenModal from "App/Components/FullScreenModal";
import PageDescription from "App/Components/PageDescription";
import CustomTable from "App/Components/CustomTable";
import CheckDotButton from "App/Components/CheckDotButton";
import { debounce } from "lodash";
import { useCardsState } from "App/Pages/Cards/Cards/store";
import CustomPagination from "App/Components/CustomPagination";
import { pluralize } from "Utils";
import { useConfigurationState } from "App/Pages/Accounting/Configuration/store";
import { useNavigate } from "react-router-dom";
import URLS from "Routes/constants";
import { useQueryClient } from "@tanstack/react-query";
import { useRefreshCodatCompanyData } from "./query/useRefreshCodatCompanyData";
import { APIROUTES } from "Constants/ApiRoutes";
import { useApiRequest } from "Hooks/API";
import CardImage from "App/Components/CardImage";
import { usePrepaidCardsQuery } from "App/Pages/Cards/query";

function Configuration() {
    const currentPage = useCardsState(state => state.currentPage);
    const limit = useCardsState(state => state.limit);
    const setCardsState = useCardsState(state => state.setState);
    const retryCount = useConfigurationState(state => state.retryCount);
    const accountingLogo = useConfigurationState(state => state.accountingLogo);
    const allCards = useConfigurationState(state => state.allCards);
    const codatBankAccountId = useConfigurationState(state => state.codatBankAccountId);
    const codatBillPaymentBankAccountId = useConfigurationState(state => state.codatBillPaymentBankAccountId);

    const selectCardsModal = useConfigurationState(state => state.selectCardsModal);
    const selectedCards = useConfigurationState(state => state.selectedCards);
    const syncCardTrans = useConfigurationState(state => state.syncCardTrans);
    const syncBankFeeds = useConfigurationState(state => state.syncBankFeeds);
    const syncExpenses = useConfigurationState(state => state.syncExpenses);
    const categoryMap = useConfigurationState(state => state.categoryMap);
    const clientId = useConfigurationState(state => state.clientId);
    const newAccountName = useConfigurationState(state => state.newAccountName);
    const newAccount = useConfigurationState(state => state.newAccount);
    const disconectConfirmModal = useConfigurationState(state => state.disconectConfirmModal);
    const resetDisconnectConfirmModal = useConfigurationState(state => state.resetDisconnectConfirmModal);
    const setDisconnectConfirmModalState = useConfigurationState(state => state.setDisconnectConfirmModalState);
    const statusId = useConfigurationState(state => state.statusId);
    const loading = useConfigurationState(state => state.loading);
    const refreshDataLoading = useConfigurationState(state => state.refreshDataLoading);
    const checkingRefreshState = useConfigurationState(state => state.checkingRefreshState);
    const setState = useConfigurationState(state => state.setState);
    const resetState = useConfigurationState(state => state.resetState);
    const platform_type = useConfigurationState(state => state.platform_type);

    const bankAccountsQuery = useBankAccountsQuery();
    const billPaymentBankAccountsQuery = useBillPaymentBankAccountsQuery();

    const accountingConfigurationQuery = useAccountingConfigurationQuery();

    const deleteAccountingConfMutation = useDeleteAccountingConfigMutation();
    const accountingConfigMutation = useAccountingConfigMutation();
    const refreshcodatMutation = useRefreshCodatCompanyData();
    const apiRequest = useApiRequest();

    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { data: prepaidCards, isLoading: prepaidCardsLoading } = usePrepaidCardsQuery();
    const refreshCount = useRef(0);

    useEffect(() => {
        if (checkingRefreshState && !accountingConfigurationQuery?.isFetching && !!accountingConfigurationQuery?.data?.company_data_refreshing_status && refreshCount.current < 12) {
            setTimeout(() => {
                queryClient.invalidateQueries({ queryKey: ['_get_accounting_config_all'], exact: true });
                refreshCount.current++
            }, 5000)
        } else if (checkingRefreshState && refreshCount.current === 12 && !!accountingConfigurationQuery?.data?.company_data_refreshing_status && !accountingConfigurationQuery?.isFetching) {
            notification.destroy();
            notification.error({ message: 'Error', description: 'Failed to refresh data, please try again', placement: 'top' });
            setState({ refreshDataLoading: false, checkingRefreshState: false })
            refreshCount.current = 0
        } else if (checkingRefreshState && !accountingConfigurationQuery?.data?.company_data_refreshing_status && !accountingConfigurationQuery?.isFetching) {
            setState({ refreshDataLoading: false, checkingRefreshState: false })
            refreshCount.current = 0
        }
    }, [accountingConfigurationQuery?.isFetching])

    //
    useEffect(() => {
        setState({ pollForStatusInterval: 4000 });
        setCardsState({ cardSearchFilter: '', limit: 10, currentPage: 1 });
        queryClient.invalidateQueries({ queryKey: ['_get_bank_accounts'], exact: true });
        queryClient.invalidateQueries({ queryKey: ['_get_accounting_config_all'], exact: true });
        return () => {
            resetState();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let retryTimeout;

        if (!!bankAccountsQuery.data?.response?.error && retryCount < 5) {
            retryTimeout = setTimeout(() => {
                bankAccountsQuery.refetch();
                setState({ retryCount: retryCount + 1 });
            }, 12000);
        }

        if (!bankAccountsQuery.data?.response?.error || retryCount === 4) {
            if (retryTimeout) {
                clearTimeout(retryTimeout);
            }
        }

        // Clear the timeout
        return () => {
            if (retryTimeout) {
                clearTimeout(retryTimeout);
            }
        };
        // eslint-disable-next-line
    }, [bankAccountsQuery.data]);


    useEffect(() => {
        let retryTimeout;

        if (!!billPaymentBankAccountsQuery.data?.response?.error && retryCount < 5) {
            retryTimeout = setTimeout(() => {
                billPaymentBankAccountsQuery.refetch();
                setState({ retryCount: retryCount + 1 });
            }, 12000);
        }

        if (!billPaymentBankAccountsQuery.data?.response?.error || retryCount === 4) {
            if (retryTimeout) {
                clearTimeout(retryTimeout);
            }
        }

        // Clear the timeout
        return () => {
            if (retryTimeout) {
                clearTimeout(retryTimeout);
            }
        };
        // eslint-disable-next-line
    }, [billPaymentBankAccountsQuery.data]);

    //
    const removeEmptyCategoryMappings = () => {
        return categoryMap.filter(map =>
            map.codat_category_id !== null && map.codat_category_id !== '' &&
            map.codat_tax_id !== null && map.codat_tax_id !== '' &&
            map.transaction_category_id !== null && map.transaction_category_id !== ''
        );
    }

    /**
     * Handle state change on switch/radio change
     *
     * @param {string} val
     * @param {string} target
     */
    const handleInputChange = (val, key) => {
        // Remove null mappings when switching Off syncExpenses
        if (key === 'syncExpenses' && !val) {
            setState({
                [key]: val,
                categoryMap: removeEmptyCategoryMappings()
            });

            return;
        }

        setState({ [key]: val });
    }

    /**
     * Handle api call to mutate account config
     *
     */
    const handleDeleteConnection = async () => {
        deleteAccountingConfMutation.mutate();
    }

    //
    const handleSelectBankAccount = (val) => {
        setState({ codatBankAccountId: val });
    }


    const handleSelectBillPaymentBankAccount = (val) => {
        setState({ codatBillPaymentBankAccountId: val });
    }


    const invalidBankAccountId = () => {
        // eslint-disable-next-line
        return !bankAccountsQuery.data?.response?.results?.find(b => b.id === codatBankAccountId) ||
            !codatBankAccountId ||
            // eslint-disable-next-line
            codatBankAccountId == '' ||
            !!bankAccountsQuery.data?.response?.error;
    }


    const invalidBillPaymentBankAccountId = () => {
        // eslint-disable-next-line
        return !billPaymentBankAccountsQuery.data?.response?.results?.find(b => b.id === codatBillPaymentBankAccountId) ||
            !codatBillPaymentBankAccountId ||
            // eslint-disable-next-line
            codatBillPaymentBankAccountId == '' ||
            !!billPaymentBankAccountsQuery.data?.response?.error;
    }

    //
    const handleSaveSettings = async () => {
        notification.destroy();

        if (invalidBankAccountId()) {
            notification.error({ description: 'Please select or create the account you wish to sync your volopa transactions with.', placement: 'top' });
            return;
        }

        // If found mappings with null values and syncExpenses On
        if (syncExpenses) {
            let emptyMappings = categoryMap.some(map =>
                !map.codat_category_id || map.codat_category_id === '' ||
                !map.codat_tax_id || map.codat_tax_id === '' ||
                !map.transaction_category_id || map.transaction_category_id === ''
            );

            if (emptyMappings) {
                notification.error({ message: 'Something Went Wrong', description: 'Please select Volopa category, Account code and VAT Code for all items', placement: 'top' });
                return;
            }
        }

        // @todo Check if sync specific cards array is empty?

        // Send the config mutation
        try {
            const res = await accountingConfigMutation.mutateAsync();
            if (res?.response?.data?.error) {
                notification.error({ message: 'Something Went Wrong', placement: 'top' });
                return;
            }
            navigate(URLS.ExpenseTransactions);
        } catch (err) {
            notification.error({ message: 'Something Went Wrong.', placement: 'top' });
        }
    }

    //
    const handleCancelSelectCards = () => {
        setState({
            selectCardsModal: false,
            selectedCards: accountingConfigurationQuery.data.cards,
            allCards: 1,
        });
    }

    //
    const handleConfirmSelectCards = () => {
        setState({
            selectCardsModal: false,
        });
    }

    //
    const handleCardSelect = (evt, val) => {
        setState({
            selectedCards: evt.target.checked
                ? [...selectedCards, val]
                : [...selectedCards.filter(c => c !== val)]
        }, 'handleCardSelect');
    }

    //
    const handleSyncAllCardsChange = (val) => {
        if (val === 0) {
            setState({ allCards: val, selectCardsModal: true });
            return;
        }
        setState({ allCards: val });
    }

    //
    const handleSyncCardsSearchQuery = debounce((evt) => {

        if (evt.target.value.length > 1) {
            setCardsState({ cardSearchFilter: evt.target.value.toLowerCase() });
        }

        if (evt.target.value.length < 1) {
            setCardsState({ cardSearchFilter: '' });
        }
    }, 400);

    //
    const syncCardsColumns = [
        {
            title: 'Card',
            dataIndex: 'id',
            key: 'card',
            width: 300,
            render: (value, record) => <Card className="b-2-g m-10">
                <Row justify="center" className="m-t-10 m-b-10">
                    <Col>
                        <CardImage cardProgram={record?.card_type} width={128} />
                    </Col>
                </Row>
            </Card>
        },
        {
            title: 'Name',
            dataIndex: ['card_holder', 'name'],
            key: 'card_holder_name',
            render: (value) => <Space direction="vertical" size={0}>
                <Typography.Text className="fs-18px regular">Card Holder Name</Typography.Text>
                <Typography.Text className="fs-18px medium dark-green">{value}</Typography.Text>
            </Space>
        },
        {
            title: 'Number',
            dataIndex: 'card_number',
            key: 'card_number',
            render: value => <Space direction="vertical" size={0}>
                <Typography.Text className="fs-18px regular">Card Number</Typography.Text>
                <Typography.Text className="fs-18px medium dark-green">**** **** **** {value}</Typography.Text>
            </Space>
        },
        {
            title: 'Expiry',
            dataIndex: 'expiry_date',
            key: 'expiry_date',
            render: (value) => <Space direction="vertical" size={0}>
                <Typography.Text className="fs-18px regular">Expiry Date</Typography.Text>
                <Typography.Text className="fs-18px medium dark-green">{value}</Typography.Text>
            </Space>
        },
        {
            title: '',
            dataIndex: 'prepaid_card_id',
            key: 'action',
            align: 'right',
            render: (value, record) => <Checkbox
                checked={selectedCards.includes(value)}
                value={value}
                className="b-2-grey big-rounded p-r-2 p-l-2"
                onChange={(evt) => handleCardSelect(evt, value)}
            />
        }
    ];

    const handlePageChange = (page) => {
        setCardsState({ currentPage: page });
    }

    const handlePageSizeChange = (current, size) => {
        setCardsState({ limit: size, currentPage: 1 });
    }

    //
    const handleNewBankAccount = () => {
        setState({ newAccount: true });
    }

    //
    const handleCreateBankAccount = async () => {
        setState({
            loading: true,
        });

        try {
            const data = await apiRequest(`${APIROUTES.post.accounting_create_bank_account}`, 'POST', { clientId, accountName: newAccountName });

            if (data?.response?.data?.error && data?.response?.data?.error?.message) {
                notification.error({ message: data?.response?.data?.error?.message, description: 'Please check the new account name and try again.', placement: 'top' });
                return;
            }
            await new Promise(resolve => setTimeout(resolve, 10000));
            queryClient.invalidateQueries({ queryKey: ['_get_bank_accounts'], exact: true });

            notification.success({ message: `${newAccountName} Account created`, placement: 'top' });

            setState({
                newAccount: false,
                newAccountName: '',
            });
        } catch (err) {
            notification.error({ message: "Something Went Wrong", placement: 'top' });
        } finally {
            setState({
                loading: false,
            });
        }
    }

    const refreshDataButtonClick = () => {
        setState({ refreshDataLoading: true })
        refreshcodatMutation.mutate();
    }

    //
    return (
        <>
            {/* Disonnect account confirmation modal =============== */}
            <Modal
                title="Confirm deletion"
                open={disconectConfirmModal.show}
                onOk={handleDeleteConnection}
                onCancel={() => resetDisconnectConfirmModal()}
                okText="Yes, disconnect."
                cancelText="CANCEL"
                bodyStyle={{ minHeight: '120px' }}
            >
                {!!disconectConfirmModal.loading
                    ? <Spinner />
                    : <Typography.Text className="fs-20px bold dark-red">
                        {disconectConfirmModal.message}
                    </Typography.Text>
                }
            </Modal>

            {/* Select cards table =============== */}
            <FullScreenModal
                open={selectCardsModal}
                closable={false}
            >
                <Row className="m-t-40">
                    <Col span={24}>
                        <PageDescription title='Select The Cards You Wish To Sync With' text='- Select Cards you wish to Sync.' />
                    </Col>
                </Row>
                <Row className="m-t-10">
                    <Col xxl={4}>
                        <Input.Search onChange={handleSyncCardsSearchQuery} placeholder="Search Card Holder" />
                    </Col>
                </Row>
                <Row className="m-t-10">
                    <Col span={24}>
                        <CustomTable
                            showHeader={false}
                            columns={syncCardsColumns}
                            dataSource={prepaidCards?.data ?? []}
                            styleAllRows
                            className='spaced-rows'
                            loading={prepaidCardsLoading}
                            rowKey="prepaid_card_id"
                            scroll={{ y: '49vh' }}
                        />
                        <CustomPagination
                            loading={loading}
                            onPageChange={handlePageChange}
                            total={prepaidCards?.pager?.count ?? 1}
                            pageSize={limit}
                            current={currentPage}
                            onPageSizeChange={handlePageSizeChange}
                            singlePageMessage={`${prepaidCards?.pager?.count ?? 1} ${pluralize(prepaidCards?.pager?.count ?? 1, 'card', 'cards')} found.`}
                        />
                        {/* <CustomTable scroll={{y: '56vh'}} rowKey="id" loading={prepaidCardsDataLoading} columns={cardColumns} dataSource={prepaidCardsData?.data || []} styleAllRows className='spaced-rows' showHeader={false} /> */}
                    </Col>
                </Row>
                <Row className="m-t-20" justify="end">
                    <Col span={12}>
                        <Space>
                            <Typography.Text className="fs-20px">
                                {selectedCards.length + " out of " + prepaidCards?.data.length + " Cards Selected  "}
                            </Typography.Text>
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <Button type="primary" danger onClick={handleCancelSelectCards}>Cancel</Button>
                            <Button type="primary" onClick={handleConfirmSelectCards}>Confirm</Button>
                        </Space>
                    </Col>
                </Row>
            </FullScreenModal>
            <Spin size="large" tip="Please wait while your data is being refreshed" spinning={refreshDataLoading}>
                {/* Page =============== */}
                <Row justify='end'>
                    <Col style={{ marginRight: 20 }}>
                        <Button type="primary" onClick={refreshDataButtonClick}>Refresh Data</Button>
                    </Col>
                    <Col>
                        <Image src={accountingLogo} preview={false} style={{ width: 80 }} />
                    </Col>
                </Row>
                {statusId === 2
                    ? <>
                        <Row align="center" justify="center">
                            <Col>
                                <Typography.Text className="fs-20px bold dark-green">
                                    Your account is being setup.
                                </Typography.Text>
                                <Spinner />
                            </Col>
                        </Row>
                    </>
                    : <>
                        <Row className="m-t-10" gutter={[16, 16]}>
                            <Col xxl={8}>
                                <Row>
                                    <Col span={24}>
                                        <Space direction="vertical" size={0}>
                                            <Typography.Text className="fs-20px bold dark-green">
                                                Which Products Do You Wish To Sync?
                                                <Tooltip placement="bottom" title="It is currently only possible to sync transactions made on your Volopa business expense cards">
                                                    <FaInfoCircle style={{ marginLeft: '5px' }} className='dark-green' />
                                                </Tooltip>
                                            </Typography.Text>
                                            <Space direction="vertical" size={4}>
                                                <Typography.Text className="fs-18px regular">Card Transactions</Typography.Text>
                                                <Radio onClick={() => handleInputChange(true, 'syncCardTrans')} checked={syncCardTrans} className="b-2-grey big-rounded p-r-2 p-l-2" />
                                            </Space>
                                        </Space>
                                    </Col>
                                </Row>
                                {!!syncCardTrans && (
                                    <>
                                        {platform_type === 'Xero' && <Row className="m-t-20">
                                            <Col span={24}>
                                                <Space direction="vertical" size={4}>
                                                    <Typography.Text className="fs-20px bold dark-green">
                                                        Do you wish to sync bank feeds/movement of funds?
                                                        <Tooltip placement="bottom" title="Selecting Yes will sync your Volopa transaction information directly with your accounting system">
                                                            <FaInfoCircle style={{ marginLeft: '2px' }} className='dark-green' />
                                                        </Tooltip>
                                                    </Typography.Text>
                                                    <SwitchWithText checked={syncBankFeeds} onChange={val => handleInputChange(val, 'syncBankFeeds')} />
                                                </Space>
                                            </Col>
                                        </Row>}
                                        <Row className="m-t-20">
                                            <Col span={24}>
                                                <Space direction="vertical" size={4}>
                                                    <Typography.Text className="fs-20px bold dark-green">
                                                        For Your Card Transactions, Do You Wish To Sync Expense Information?
                                                        <Tooltip placement="bottom" title="Selecting Yes will sync the expense information associated with your Volopa transactions, including account codes, VAT codes & payment notes, directly with your accounting system">
                                                            <FaInfoCircle style={{ marginLeft: '5px' }} className='dark-green' />
                                                        </Tooltip>
                                                    </Typography.Text>
                                                    <SwitchWithText checked={syncExpenses} onChange={val => handleInputChange(val, 'syncExpenses')} />
                                                </Space>
                                            </Col>
                                        </Row>
                                        <Row className="m-t-20">
                                            <Col span={24}>
                                                <Row>
                                                    <Col flex='auto'>
                                                        <Typography.Text className="fs-20px bold dark-green">
                                                            Which Volopa Cards Do You Wish To Sync?
                                                        </Typography.Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col flex='auto'>
                                                        <Row>
                                                            <Col span={12}>
                                                                <Space direction="vertical">
                                                                    <Typography.Text className="fs-18px regular">All Cards</Typography.Text>
                                                                    <CheckDotButton checked={allCards === 1} onClick={() => handleSyncAllCardsChange(1)} />
                                                                </Space>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Space direction="vertical">
                                                                    <Typography.Text className="fs-18px regular">Specific Cards</Typography.Text>
                                                                    <CheckDotButton checked={allCards === 0} onClick={() => handleSyncAllCardsChange(0)} />
                                                                </Space>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className="m-t-20">
                                            {platform_type !== 'Dynamics 365 Business Central' && (
                                                <Col span={24}>
                                                    <Space direction="vertical" size={4}>
                                                        <Typography.Text className="fs-20px bold dark-green">
                                                            Select The Account You Wish To Sync Your Volopa Transactions With
                                                            <Tooltip placement="bottom" title="Select the bank account in your accounting system, with which you wish to sync your Volopa transactions with. Alternatively, if you don’t have an account to sync your Volopa transactions with, you can create one using the button below. Please note that the bank account must be in the same currency as your Volopa Wallet base currency">
                                                                <FaInfoCircle style={{ marginLeft: '5px' }} className='dark-green' />
                                                            </Tooltip>
                                                        </Typography.Text>

                                                        {!!bankAccountsQuery.data?.response?.error &&
                                                            <Typography.Text className="fs-12px error">
                                                                Please wait while accounts are being synchronised.
                                                            </Typography.Text>
                                                        }
                                                        <Row>
                                                            <Col span={14}>
                                                                <Space direction="vertical">
                                                                    <Select
                                                                        style={{ width: '232px' }}
                                                                        key={JSON.stringify(bankAccountsQuery.data?.response?.results)}
                                                                        loading={bankAccountsQuery.isLoading || bankAccountsQuery.isRefetching || bankAccountsQuery.isFetching || !!bankAccountsQuery.data?.response?.error}
                                                                        size="large"
                                                                        value={invalidBankAccountId() ? null : codatBankAccountId}
                                                                        placeholder="Select account"
                                                                        onChange={(val) => handleSelectBankAccount(val)}
                                                                        className="select-b-g"
                                                                    >
                                                                        {!!bankAccountsQuery.data?.response?.results?.length && bankAccountsQuery.data?.response?.results?.map(bank =>
                                                                            <Select.Option key={bank.id} value={bank.id}>
                                                                                {bank.accountName} {bank.nominalCode ? `(${bank.nominalCode})` : ''}
                                                                            </Select.Option>
                                                                        )}
                                                                    </Select>
                                                                    {!!newAccount &&
                                                                        <Input
                                                                            placeholder="Type an account name"
                                                                            style={{ height: '40px', width: '232px' }}
                                                                            value={newAccountName}
                                                                            onChange={(evt) => setState({ newAccountName: evt.target.value })} />
                                                                    }
                                                                </Space>
                                                            </Col>

                                                            <Col span={10}>
                                                                <>
                                                                    {!newAccount ? (
                                                                        <Button
                                                                            onClick={handleNewBankAccount}
                                                                            disabled={bankAccountsQuery.isLoading || bankAccountsQuery.isRefetching || bankAccountsQuery.isFetching || loading || !!bankAccountsQuery.data?.response?.error}
                                                                            loading={bankAccountsQuery.isLoading || bankAccountsQuery.isRefetching || bankAccountsQuery.isFetching || loading || !!bankAccountsQuery.data?.response?.error}
                                                                            size="large" type="primary"
                                                                        >Create New Account</Button>
                                                                    ) : (
                                                                        <Button
                                                                            onClick={handleCreateBankAccount}
                                                                            disabled={bankAccountsQuery.isLoading || bankAccountsQuery.isRefetching || bankAccountsQuery.isFetching || loading || !newAccountName}
                                                                            loading={bankAccountsQuery.isLoading || bankAccountsQuery.isRefetching || bankAccountsQuery.isFetching || loading}
                                                                            size="large" type="primary"
                                                                        >Save New Account</Button>
                                                                    )}
                                                                </>
                                                            </Col>
                                                        </Row>
                                                    </Space>
                                                </Col>
                                            )}

                                            {platform_type === 'Dynamics 365 Business Central' && (
                                                <Col span={24}>
                                                    <Space direction="vertical" size={4}>
                                                        <Typography.Text className="fs-20px bold dark-green">
                                                            Select The Accounts You Wish To Sync Your Volopa Transactions With
                                                        </Typography.Text>
                                                        {!!bankAccountsQuery.data?.response?.error &&
                                                            <Typography.Text className="fs-12px error">
                                                                Please wait while accounts are being synchronised.
                                                            </Typography.Text>
                                                        }
                                                        <Row style={{ marginBottom: '10px' }}>
                                                            <Col span={16}>
                                                                <Row>
                                                                    <Col span={10}>
                                                                        <Typography.Text className="fs-20px bold dark-green">
                                                                            Direct Costs
                                                                            <Tooltip placement="bottom" title="Select the account with which you wish to sync Volopa transactions as general expenses">
                                                                                <FaInfoCircle style={{ marginLeft: '5px' }} className='dark-green' />
                                                                            </Tooltip>
                                                                        </Typography.Text>

                                                                    </Col>
                                                                    <Col>
                                                                        <Space direction="vertical">
                                                                            <Select
                                                                                style={{ width: '232px' }}
                                                                                loading={bankAccountsQuery.isLoading || bankAccountsQuery.isRefetching || bankAccountsQuery.isFetching || !!bankAccountsQuery.data?.response?.error}
                                                                                size="large"
                                                                                value={invalidBankAccountId() ? null : codatBankAccountId}
                                                                                placeholder="Select account"
                                                                                onChange={(val) => handleSelectBankAccount(val)}
                                                                                className="select-b-g"
                                                                            >
                                                                                {!!bankAccountsQuery.data?.response?.results?.length && bankAccountsQuery.data?.response?.results?.map(bank =>
                                                                                    <Select.Option key={bank.id} value={bank.id}>
                                                                                        {bank.accountName} {bank.nominalCode ? `(${bank.nominalCode})` : ''}
                                                                                    </Select.Option>
                                                                                )}
                                                                            </Select>
                                                                            {!!newAccount &&
                                                                                <Input
                                                                                    placeholder="Type an account name"
                                                                                    style={{ height: '40px', width: '232px' }}
                                                                                    value={newAccountName}
                                                                                    onChange={(evt) => setState({ newAccountName: evt.target.value })} />
                                                                            }
                                                                        </Space>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        {!!billPaymentBankAccountsQuery.data?.response?.error &&
                                                            <Typography.Text className="fs-12px error">
                                                                Please wait while accounts are being synchronised.
                                                            </Typography.Text>
                                                        }

                                                        <Row>
                                                            <Col span={16}>
                                                                <Row>
                                                                    <Col span={10}>
                                                                        <Typography.Text className="fs-20px bold dark-green">
                                                                            Bill Payments
                                                                            <Tooltip placement="bottom" title="Select the account with which you wish to sync Volopa transactions as bill payments">
                                                                                <FaInfoCircle style={{ marginLeft: '5px' }} className='dark-green' />
                                                                            </Tooltip>
                                                                        </Typography.Text>
                                                                    </Col>
                                                                    <Col>
                                                                        <Space direction="vertical">
                                                                            <Select
                                                                                style={{ width: '232px' }}
                                                                                loading={billPaymentBankAccountsQuery.isLoading || billPaymentBankAccountsQuery.isRefetching || billPaymentBankAccountsQuery.isFetching || !!billPaymentBankAccountsQuery.data?.response?.error}
                                                                                size="large"
                                                                                value={invalidBillPaymentBankAccountId() ? null : codatBillPaymentBankAccountId}
                                                                                placeholder="Select account"
                                                                                onChange={(val) => handleSelectBillPaymentBankAccount(val)}
                                                                                className="select-b-g"
                                                                            >
                                                                                {!!billPaymentBankAccountsQuery.data?.response?.results?.length && billPaymentBankAccountsQuery.data?.response?.results?.map(bank =>
                                                                                    <Select.Option key={bank.id} value={bank.id}>
                                                                                        {bank.accountName} {bank.nominalCode ? `(${bank.nominalCode})` : ''}
                                                                                    </Select.Option>
                                                                                )}
                                                                            </Select>

                                                                        </Space>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Space>
                                                </Col>
                                            )}
                                        </Row>
                                    </>
                                )}
                            </Col>

                            {/* Category mappings ================== */}
                            <Col
                                xxl={16} xl={24} lg={24} md={24}
                                style={{ paddingBottom: '1rem', overflowX: 'scroll', marginBottom: '2rem', minWidth: '806.7px' }}
                            >
                                <AccountingCategoryMapping disabled={invalidBankAccountId()} />
                            </Col>

                        </Row>
                        {syncCardTrans && (
                            <Row justify="end">
                                <Col>
                                    <Button
                                        disabled={invalidBankAccountId()}
                                        loading={accountingConfigMutation.isLoading}
                                        onClick={handleSaveSettings}
                                        type="primary"
                                        size="large"
                                    >
                                        Save Settings
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        <Row justify="end" className="m-t-20">
                            <Col>
                                <Space size={4}>
                                    <Typography.Text className="muli light fs-18px">
                                        If you wish to disconnect Volopa from your accounting software, please click
                                    </Typography.Text>
                                    <Button
                                        loading={loading}
                                        style={{ padding: 0 }}
                                        onClick={() => setDisconnectConfirmModalState('show', true)}
                                        type="link"
                                        className="dark-green underline muli bold fs-18px"
                                    >
                                        here
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </>
                }
            </Spin>
        </>
    )
}

export default Configuration;
