import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useTransactionsExpensesState } from "App/Pages/Accounting/Transactions/Expenses/store";
import { useRefreshTransactionsQuery } from "App/Pages/Accounting/Transactions/query/useRefreshTransactionsQuery";

function useAccountingExpensesQuery() {
    const apiRequest = useApiRequest();
    const page = useTransactionsExpensesState(state => state.page);
    const limit = useTransactionsExpensesState(state => state.limit);
    const syncStatus = useTransactionsExpensesState(state => state.syncStatus);
    const orderByField = useTransactionsExpensesState(state => state.orderByField);
    const setState = useTransactionsExpensesState(state => state.setState);
    const { isFetching, isLoading } = useRefreshTransactionsQuery();

    const syncStatusKey = syncStatus === "1" ? 'synced' : 'notsynced';

    const fetchQuery = async () => {
        let url = `${APIROUTES.get_accounting_transactions_expenses}&$skip=${(page - 1) * limit}&$limit=${limit}&inSynchId=${syncStatus}`
        if (orderByField) {
            url += `&orderByField=${orderByField}`
        }
        const transactions = await apiRequest(url);

        return transactions;
    }

    const query = useQuery({
        queryKey: [`_get_accounting_transactions_expenses_${syncStatusKey}`, page, limit, orderByField],
        queryFn: fetchQuery,
        // Keeping pages in cache so we don't call
        //the server for already fetched pages
        keepPreviousData: true,
        onSuccess: (data) => {
            setState({
                totalPages: data?.pager?.count,
            });
        },
        enabled: !isFetching && !isLoading
    });

    return query;
}

export { useAccountingExpensesQuery };