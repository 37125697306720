import PageDescription from "App/Components/PageDescription";
import { use2faQrQuery } from "App/Pages/Settings/PersonalProfile/EnableTwoFactor/query";
import { useTwoFactorState } from "App/Pages/Settings/PersonalProfile/EnableTwoFactor/store";
import { usePersonalProfileQuery } from "App/Pages/Settings/PersonalProfile/query";
import { APIROUTES } from "Constants/ApiRoutes";
import { useApiRequest } from "Hooks/API";
import URLS from "Routes/constants";
import { Button, Card, Col, Form, Input, Row, Space, Spin, Typography, notification } from "antd";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";

function EnableTwoFactor() {
  const { data, isFetching } = use2faQrQuery();
  const loading = useTwoFactorState(s => s.loading);
  const setState = useTwoFactorState(s => s.setState);
  const apiRequest = useApiRequest();
  const navigate = useNavigate();
  const { data: personalProfile, isFetching: profileLoading, refetch } = usePersonalProfileQuery();

  const handleFinish = async (values) => {
    const code = values?.code;
    const secret = data?.secret;
    setState({ loading: true });
    const res = await apiRequest(APIROUTES.post.verify_2fa, 'POST', { code, secret }, undefined, undefined, true);
    if (res?.secret_2FA) {
      refetch();
    } else {
      notification.error({
        message: 'Error',
        description: '2FA enable failed, please try again.',
        placement: 'top'
      })
    }
    setState({ loading: false })
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <PageDescription title="Two-Factor - Authenticator" />
        </Col>
      </Row>
      <Row className="m-t-20">
        <Col span={24}>
          <Space direction="vertical" size={0}>
            <Typography.Text className="dark-green fs-28px medium">What is Google Authenticator?</Typography.Text>
            <Typography.Text className="dark-green fs-18px regular">Google Authenticator generates 2-Step Verification codes on your phone.</Typography.Text>
            <Typography.Text className="dark-green fs-18px regular">2-Step Verification provides stronger security for your Volopa Account by requiring a second step of verification when you sign in.</Typography.Text>
            <Typography.Text className="dark-green fs-18px regular">In addition to your password, you'll also need a code generated by the Google Authenticator app on your phone.</Typography.Text>
          </Space>
        </Col>
      </Row>
      <Row className="m-t-20" justify="space-between" gutter={[24, 24]}>
        <Col>
          <Row>
            <Col>
              <Typography.Text className="dark-green bold fs-30px">Scan Me</Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card hoverable className="b-g">
                {isFetching ?
                  <Spin size='large' spinning /> : (
                    data?.qrCodeStr &&
                    <QRCode style={{ marginBottom: '-5px' }} value={data?.qrCodeStr} />
                  )
                }
              </Card>
            </Col>
          </Row>
        </Col>
        <Col flex='570px'>
          <Card hoverable {...(!personalProfile?.user?.secret_2FA && { className: 'danger' })}>
            <Spin size="large" spinning={profileLoading}>
              {personalProfile?.user?.secret_2FA ? (
                <>
                  <Row justify="center">
                    <Col>
                      <Typography.Text className="fs-22px bold center-align-text" type="success">2FA Google Authenticator is enabled on your account</Typography.Text>
                    </Col>
                  </Row>
                  <Row justify="center" className="m-t-40">
                    <Col>
                      <Typography.Text className="fs-20px muli semi-bold center-align-text">Two factor Authentication has successfully been enabled to your account</Typography.Text>
                    </Col>
                  </Row>
                  <Row justify="center" className="m-t-40">
                    <Col>
                      <Button type="primary" onClick={() => navigate(URLS.PersonalProfile)}>My Profile</Button>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row justify="center">
                    <Col>
                      <Typography.Text className="fs-22px bold center-align-text" type="danger">2FA Google Authenticator is disabled on your account</Typography.Text>
                    </Col>
                  </Row>
                  <Row justify="center" className="m-t-20">
                    <Col>
                      <Typography.Text className="fs-20px muli semi-bold center-align-text">Scan the QR code in the Google Authenticator application and enter the code below.</Typography.Text>
                    </Col>
                  </Row>
                  <Form
                    requiredMark={false}
                    onFinish={handleFinish}
                  >
                    <Row justify="center" className="m-t-20">
                      <Col flex='250px'>
                        <Form.Item
                          name='code'
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the code'
                            },
                            {
                              len: 6,
                              message: 'Please Enter a valid code'
                            }
                          ]}
                        >
                          <Input.Password placeholder="Enter Code" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col>
                        <Button type="primary" htmlType="submit" loading={loading}>Enable 2FA</Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </Spin>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export { EnableTwoFactor };