import { Button, Modal, Upload, Carousel, Typography, Row, Col, notification, Spin } from 'antd';
import { useRef } from 'react';
import pdfIconImg from "Assets/Images/ico-pdf.png";
import docIconImg from "Assets/Images/ico-doc.png";
import { FaPaperclip, FaChevronLeft, FaChevronRight, FaDownload, FaTrash, FaWindowClose } from 'react-icons/fa';
import { usePaymentFilesState } from 'App/Pages/Payments/PaymentHistory/SpecificPaymentHistory/PaymentAttachmentUpload/store';
import { useApiRequest } from 'Hooks/API';
import { useExtendedApiRequest } from 'Hooks/ExtendedAPI';
import { urlObjectCreator } from 'Utils';
import { APIROUTES } from 'Constants/ApiRoutes';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const PaymentAttachmentUpload = ({
    onChange = () => { },
    type = 'payment'
}) => {
    const previewOpen = usePaymentFilesState((state) => state.previewOpen);
    const currentPreviewSlide = usePaymentFilesState((state) => state.currentPreviewSlide);
    const fileStore = usePaymentFilesState((state) => state.fileStore);
    const setState = usePaymentFilesState((state) => state.setState);
    const loading = usePaymentFilesState((state) => state.loading);
    const previewCarouselRef = useRef();
    const apiRequest = useApiRequest();
    const extendedApiRequest = useExtendedApiRequest();

    const handleCancel = () => setState({ previewOpen: false });

    const handleChange = async ({ file, fileList }) => {
        setState({ loading: true });

        if ((file.size / 1048576) > 4) {
            fileList = fileList.filter(item => item.uid !== file.uid);
            notification.error({ message: 'Maximum file size is 4MB.', placement: 'top' });
            setState({ loading: false });
            return;
        }

        // Uploaded file has thumbUrl dataSrc,
        // but we need a large img for modal
        const fileListPromises = fileList.map(async file => {
            if (file.originFileObj && file.originFileObj.type.includes('image') && (!file.thumb || !file.url)) {
                const preview = await getBase64(file.originFileObj);
                return {
                    ...file,
                    id: file.uid, // aligned with api response,
                    thumb: preview,
                    url: preview,
                }
            } else {
                return file;
            }
        });

        const newFileList = await Promise.all(fileListPromises);

        setState({
            fileStore: newFileList,
        });

        setState({ loading: false });
        onChange();
    };

    //
    const handleOpenPreviewImage = (file) => {
        let src = null;
        if (file.type === 'application/pdf' || file.file_extension === 'pdf') {
            src = pdfIconImg;
        } else if (file.file_extension === 'docx' || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            src = docIconImg;
        }
        else {
            src = file.url || null
        }

        let slideIdx = fileStore.findIndex(fileItem => fileItem.uid === file.uid);

        setState({
            previewImage: src,
            previewOpen: true,
            setPreviewTitle: file.name || file?.url?.substring(file.url.lastIndexOf('/') + 1),
            currentPreviewSlide: slideIdx ?? 0,
        });

        if (previewCarouselRef.current) {
            previewCarouselRef.current.goTo(slideIdx ?? 0);
        }
    }

    //
    const ThumbBox = ({ origin, file }) => {
        let src = (file.file_extension === 'docx' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ?
            docIconImg : (file.type === 'application/pdf' || file.file_extension === 'pdf') ? pdfIconImg : file.thumb;
        return <Button onClick={() => handleOpenPreviewImage(file)} style={{ height: '101px', width: '101px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={src} style={{ width: '100%' }} alt={file.file_name} />
        </Button>
    }

    //
    const handleSlidePage = (prev = false) => {
        if (previewCarouselRef.current) {
            if (prev) {
                previewCarouselRef.current.prev();
                return;
            }

            previewCarouselRef.current.next();
        }
    }

    //
    const uploadButton = (
        <div>
            <FaPaperclip />
            <div style={{ marginTop: 8, position: 'relative' }}>Upload</div>
        </div>
    );

    //
    const handleDelete = async (file) => {
        if (!file?.originFileObj) {
            const deleteResponse = await apiRequest("file/file", "DELETE", JSON.stringify({ uuid: file.uuid, deleteType: "payment" }));
            if (deleteResponse && deleteResponse.data && deleteResponse.data.success) {
                notification.success({ message: 'Attachment Deleted', placement: 'top' });
                setState({
                    previewOpen: false,
                    fileStore: [...fileStore.filter(item => item.uuid !== file.uuid)]
                })
            } else {
                notification.error({ message: 'Something Went Wrong', description: 'Attachment Deletion Unsuccessful', placement: 'top' });
            }
        } else {
            setState({
                previewOpen: false,
                fileStore: [...fileStore.filter(item => item.uid !== file.uid)]
            })
        }
    }
    async function downloadFile(file) {
        if (!!file.originFileObj) {
            notification.info({ placement: 'top', message: 'Attachment not Saved', description: 'Please first save Attachment by clicking on save button.' });
        } else if (file.file_extension === 'pdf' || file.file_extension === 'docx') {
            setState({ loading: true });
            try {
                const res = await extendedApiRequest({
                    baseUrl: process.env.REACT_APP_API_BASE_URL,
                    path: `${APIROUTES.get.file_file}?uuid=${file.uuid}`,
                    method: 'GET',
                    config: {
                        responseType: "blob",
                    },
                    fullResponse: true,
                })

                if (res.status === 200) {
                    const href = urlObjectCreator(res.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', file?.file_name);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                }
                else {
                    throw new Error('Something went wrong while fetching your attachment');
                }
            } catch (err) {
                notification.error({ placement: 'top', message: 'Something went wrong', description: 'Attachment not found' });
            } finally {
                setState({
                    loading: false
                });
            }
        } else {
            let a = document.createElement('a');
            a.href = file.url;
            a.download = file.name || file?.url?.substring(file.url.lastIndexOf('/') + 1) + file.file_extension;
            a.click();
            a.remove();
        }

    }


    return (
        <Spin spinning={loading}>
            {/* Upload box ======================== */}
            <Upload
                className="transaction-upload-component"
                customRequest={() => { }}
                listType="picture-card"
                fileList={fileStore}
                onChange={handleChange}
                accept=".png,.jpg,.jpeg,.pdf,.docx"
                itemRender={(origin, file) => <ThumbBox origin={origin} file={file} />}
                multiple={true}
            >
                {fileStore.length >= 8 ? null : uploadButton}
            </Upload>

            {/* Preview modal ======================== */}
            <Modal
                width={`33vw`}
                open={previewOpen}
                footer={false}
                closable={false}
                onCancel={handleCancel}
                iconRender={<FaPaperclip />}
            >
                <Spin spinning={loading}>

                    <div style={{ position: 'relative', padding: '0 30px' }}>
                        <FaWindowClose onClick={handleCancel} size={24} className='dark-green' style={{ position: 'absolute', right: 0, top: 0, cursor: 'pointer' }} />
                        <FaChevronLeft onClick={() => handleSlidePage(true)} size={30} style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 2, cursor: 'pointer' }} />
                        <FaChevronRight onClick={() => handleSlidePage()} size={30} style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 2, cursor: 'pointer' }} />

                        <Carousel infinite={true} initialSlide={currentPreviewSlide} ref={(ref) => previewCarouselRef.current = ref}>
                            {fileStore.length && fileStore.map((file, idx) => {
                                let src = null;
                                if (file.type === 'application/pdf' || file.file_extension === 'pdf') {
                                    src = pdfIconImg;
                                } else if (file.file_extension === 'docx' || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                                    src = docIconImg;
                                } else {
                                    src = file.url || null;
                                }

                                return (
                                    <div key={idx} style={{ position: 'relative' }}>
                                        <div style={{ height: '60vh', display: 'flex', alignItems: 'center' }}>
                                            <img alt={file.name} style={{ width: '100%', padding: 4 }} src={src} />
                                        </div>
                                        <Row style={{ marginTop: 30, padding: '0 5px' }} align="middle" justify="space-between">
                                            <Col span={3}>
                                                <Button onClick={() => handleDelete(file)} type="danger">
                                                    <FaTrash />
                                                </Button>
                                            </Col>
                                            <Col span={18} >
                                                <Typography.Text style={{ display: 'flex', justifyContent: 'center' }} className="dark-green">{file.file_name}</Typography.Text>
                                            </Col>
                                            <Col span={3} style={{ textAlign: 'right' }}>
                                                <Button type="info" className='dark-green' onClick={() => downloadFile(file)}>
                                                    <FaDownload />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })}
                        </Carousel>
                    </div>
                </Spin>
            </Modal>
        </Spin>
    );
};

export default PaymentAttachmentUpload;