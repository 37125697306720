import { Button, Col, Form, Image, Input, Row, Select, Space, Typography, Spin, Card, notification, Tooltip, InputNumber } from "antd";
import PageDescription from 'App/Components/PageDescription';
import { FaInfoCircle } from 'react-icons/fa';
import CardDetails from "App/Components/CardDetails";
import { useParams, Link } from "react-router-dom";
import { useTransactionQuery } from "../query";
import { useTransactionCategoriesQuery } from "App/Pages/Accounting/Configuration/query";
import moment from "moment";
import { useTransactionState } from "./store";
import TransactionAttachmentUpload from "App/Components/TransactionAttachmentUpload";
import { FLAGS } from "Constants/Images";
import { useEffect } from "react";
import { useTransactionExpenseMutation } from "../query";
import CardBalanceListing from "App/Components/CardBalanceListing";
import URLS from "Routes/constants";
import { useQueryClient } from "@tanstack/react-query";
import { useExpenseAttachmentMutation, useTxCategoryMapMutation, useTxVatMapMutation } from "Hooks/Mutations";
import { isEqual } from "lodash";
import { accountingFormat, numberFormat } from "Utils";
import { useApiRequest } from "Hooks/API";
import { FORMATS } from "Constants/Formats";
import { useNavigate } from "react-router-dom";

function SpecificCardTransaction() {
    const { txId } = useParams();
    const navigate = useNavigate();

    // eslint-disable-next-line
    const {
        data: transaction,
        isLoading,
        refetch,
        isFetched: isTxnQueryFetched,
        isFetching: isTxnQueryFetching,
        isRefetching: isTxnQueryRefetching
    } = useTransactionQuery(txId);
    const { data: transactionCategories, isLoading: transactionCategoriesLoading } = useTransactionCategoriesQuery();
    const transactionId = useTransactionState(state => state.transactionId);
    const expenseNotes = useTransactionState(state => state.expenseNotes);
    const transaction_category = useTransactionState(state => state.transaction_category);
    const fileStore = useTransactionState(state => state.fileStore);
    const transaction_vat = useTransactionState(state => state.transaction_vat);
    const initData = useTransactionState(state => state.initData);
    const isSaving = useTransactionState(state => state.isSaving);
    const setState = useTransactionState(state => state.setState);
    const fileLoading = useTransactionState(state => state.fileLoading);

    const queryClient = useQueryClient();
    const apiRequest = useApiRequest();

    const transactionExpenseMutation = useTransactionExpenseMutation(payload => payload);
    const expenseAttachmentMutation = useExpenseAttachmentMutation(payload => payload);
    const txCategoryMapMutation = useTxCategoryMapMutation(payload => payload);
    const txVatMapMutation = useTxVatMapMutation(payload => payload);

    useEffect(() => {
        if (isTxnQueryFetched && !transaction) {
            navigate(URLS.TransactionHistory);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTxnQueryFetched]);

    //
    useEffect(() => {
        // reset store on unmount
        return () => {
            setState({
                transactionId: null,
                transaction_category: null,
                transaction_vat: null,
                expenseNotes: '',
                expenseId: null,
                fileStore: [],
                attachments: [],
            });
        }

        // eslint-disable-next-line
    }, []);

    //
    const handleInputChange = (evt) => {
        setState({
            [evt.target.name]: evt.target.value
        });
    }

    //
    const handleCategorySelectChange = (val) => {
        const isOther = transactionCategories.find(txCat => txCat.id === Number(val))?.description.toLowerCase() === 'other';

        setState({
            transaction_category: {
                ...transaction_category,
                transaction_category_id: Number(val),
                is_other: isOther,
                other: !isOther ? '' : transaction_category?.other,
            }
        });
    }

    //
    const handleCatOtherInputChange = (evt) => {
        setState({
            transaction_category: {
                ...transaction_category,
                other: evt.target.value,
            }
        });
    }

    //
    const handleVatInputChange = (evt) => {
        setState({
            transaction_vat: {
                ...transaction_vat,
                other: evt != null ? `${evt}` : evt,
            }
        });
    }

    //
    const initDataChanged = () => {
        return !isEqual(
            initData,
            { fileStore, expenseNotes, transaction_category, transaction_vat }
        )
    }

    //
    const fileStoreChanged = () => {
        return !isEqual(
            fileStore,
            initData.fileStore,
        )
    }

    //
    const expenseNotesChanged = () => {
        return !isEqual(
            expenseNotes,
            initData.expenseNotes,
        )
    }

    //
    const handleFileStoreActions = () => {
        return {
            deleted: initData.fileStore.filter(item => fileStore.find(file => file.id === item.id) ? false : true),
            newFiles: fileStore.filter(file => !!file.originFileObj)
        }
    }

    //
    const handleSave = async () => {
        setState({ isSaving: true });
        const mutations = [];
        let expenseTempId = transaction?.expense?.id;

        if (!expenseTempId) {
            const data = {};
            data.transaction_id = transaction.id;
            data.expense_type_id = 10;
            data.expense_type_description = "Attachment";
            data.submitted = 0;
            const expenseResponse = await apiRequest("expense", "POST", data);
            if (expenseResponse && expenseResponse.data) {
                expenseTempId = expenseResponse.data.last_inserted_id;
            } else {
                notification.error({ message: 'Something Went Wrong', description: 'There was an issue with saving your upload', placement: 'top' });
            }
        }

        if (expenseTempId) {
            if (fileStoreChanged()) {
                const { deleted, newFiles } = handleFileStoreActions();

                if (deleted?.length) {
                    // @todo Missing del route
                    // console.log('deleted', deleted)
                }

                if (newFiles?.length) {
                    const formData = new FormData();

                    newFiles.forEach((file, idx) => {
                        formData.append(`file[${idx}]`, file.originFileObj);
                    });

                    formData.append('expense_id', expenseTempId);
                    formData.append('description', 'Transaction expense');

                    mutations.push(expenseAttachmentMutation.mutateAsync(formData));
                }
            }

            if (expenseNotesChanged()) {
                mutations.push(transactionExpenseMutation.mutateAsync(expenseTempId));
            }
        }


        if (!isEqual(transaction_category, initData.transaction_category)) {
            mutations.push(
                txCategoryMapMutation.mutateAsync({ txId, id: transaction_category?.id, txCat: transaction_category })
            );
        }

        if (!isEqual(transaction_vat, initData.transaction_vat)) {
            mutations.push(
                txVatMapMutation.mutateAsync({ txId, id: transaction_vat?.id, txVat: transaction_vat })
            );
        }

        if (mutations?.length) {
            try {
                await Promise.all(mutations);
                queryClient.invalidateQueries({ queryKey: ['_transaction', transactionId], exact: true });
                refetch();
                notification.success({ message: 'Transaction details saved!', placement: 'top' });
            } catch (err) {
                notification.error({ message: 'Something went wrong!', placement: 'top' });
            }
        }
        setState({ isSaving: false })
    }

    //
    return (
        <Spin size="large" spinning={isLoading || isTxnQueryFetching || isTxnQueryRefetching || fileLoading}>
            <Row>
                <Col span={24}>
                    <PageDescription title='Specific Card Transaction' text='- View this previous transaction, with the ability to add notes and attachments.' />
                </Col>
            </Row>

            <Row className="m-t-10" gutter={[12, 24]}>
                <Col xxl={12} xl={10} lg={12} md={16} sm={20} xs={24}>
                    <Row>
                        <Col xxl={16} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <CardDetails
                                cardHolderName={transaction?.extended_prepaid_card?.embossed_name}
                                cardNumber={`**** **** **** ${transaction?.extended_prepaid_card?.card_number}`}
                                expiryDate={`${transaction?.extended_prepaid_card?.expiry_month}/${transaction?.extended_prepaid_card?.expiry_year}`}
                                cardType={transaction?.extended_prepaid_card?.card_program_id || transaction?.extended_prepaid_card?.card_type}
                            />
                        </Col>
                    </Row>
                    <Row className="m-t-10">
                        <Col span={24}>
                            <Space direction="vertical" size={16}>
                                <Typography.Text className="dark-green fs-25px medium">Transaction Details</Typography.Text>
                                <Space direction="vertical" size={4} className='line-height-18px'>
                                    <Typography.Text className="fs-18px dark-green muli semi-bold">Transaction ID</Typography.Text>
                                    <Typography.Text className="fs-18px medium">{txId}</Typography.Text>
                                </Space>
                                <Space direction="vertical" size={4} className='line-height-18px'>
                                    <Typography.Text className="fs-18px dark-green muli semi-bold">Transaction Date</Typography.Text>
                                    <Typography.Text className="fs-18px medium">
                                        {moment(transaction?.date_created).isValid() ? moment(transaction?.date_created).format(FORMATS.date) : "-"}
                                    </Typography.Text>
                                </Space>
                                <Space direction="vertical" size={4} className='line-height-18px'>
                                    <Typography.Text className="fs-18px dark-green muli semi-bold">Transaction Status</Typography.Text>
                                    <Typography.Text
                                        className="fs-18px medium"
                                        type={transaction?.transaction_status_id ||
                                            transaction?.transaction_record_type_id === 2 ||
                                            transaction?.transaction_record_type_id === 4 ? 'success' : 'warning'}
                                    >
                                        {transaction?.transaction_status_id ||
                                            transaction?.transaction_record_type_id === 2 ||
                                            transaction?.transaction_record_type_id === 4 ? 'Processed' : 'Pending'}
                                    </Typography.Text>
                                </Space>
                                <Space direction="vertical" size={4} className='line-height-18px'>
                                    <Typography.Text className="fs-18px dark-green muli semi-bold">Merchant Name</Typography.Text>
                                    <Typography.Text className="fs-18px medium" >
                                        {transaction?.merchant_name}
                                    </Typography.Text>
                                </Space>
                                <Space direction="vertical" size={4} className='line-height-18px'>
                                    <Typography.Text className="fs-18px dark-green muli semi-bold">Merchant Description</Typography.Text>
                                    <Typography.Text className="fs-18px medium" >
                                        {transaction?.description}
                                    </Typography.Text>
                                </Space>
                            </Space>
                        </Col>
                    </Row>
                    <Form layout="vertical">
                        {!isLoading && !fileLoading && <>
                            <Row className="m-t-20">
                                <Col>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">Amount</Typography.Text>
                                </Col>
                            </Row>
                            <Row className="m-t-10" gutter={6}>
                                <Col flex='90px'>
                                    <Form.Item>
                                        <Input
                                            prefix={
                                                FLAGS[transaction?.currency?.currency] &&
                                                <Image
                                                    src={FLAGS[transaction?.currency?.currency]}
                                                    alt={transaction?.currency?.currency}
                                                    preview={false}
                                                    width={24}
                                                />
                                            }
                                            disabled
                                            className="b-g dark-green"
                                            value={transaction?.currency?.currency}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item>
                                        <Input disabled name="amount" className="b-g dark-green" value={transaction?.debit_credit === 1 ? `+${accountingFormat(transaction?.amount)}` : `-${accountingFormat(transaction?.amount)}`} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>}
                        <Row gutter={6}>
                            <Col flex='250px'>
                                <Form.Item
                                    label={<span className="muli semi-bold" size={0} align='start'>Notes</span>}
                                >
                                    <Input.TextArea
                                        onChange={handleInputChange}
                                        name="expenseNotes"
                                        className="b-g"
                                        value={expenseNotes}
                                        autoSize={{ minRows: 5, maxRows: 5 }}
                                        placeholder='Enter Additional Information'
                                    />
                                </Form.Item>
                            </Col>
                            <Col flex='200px'>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            label={<span className="muli semi-bold">Category</span>}
                                            className="m-b-15"
                                        >
                                            <Select
                                                loading={transactionCategoriesLoading}
                                                value={transaction_category?.transaction_category_id}
                                                placeholder="Search Category"
                                                name="transaction_category"
                                                onChange={(val) => handleCategorySelectChange(val)}
                                                className="select-b-g full-percent-width"
                                                showSearch
                                                filterOption={(input, option) =>
                                                    (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                            >
                                                {transactionCategories?.length && transactionCategories
                                                    .sort((a, b) => a.description.localeCompare(b.description))
                                                    .map(cat =>
                                                        <Select.Option key={cat.id} value={cat.id}>
                                                            {cat.description || 'No description'}
                                                        </Select.Option>
                                                    )}
                                            </Select>
                                        </Form.Item>
                                        {!!transaction_category?.is_other && <Form.Item>
                                            <Input
                                                value={transaction_category?.other}
                                                onChange={handleCatOtherInputChange}
                                                placeholder="Enter category name"
                                                className="b-g"
                                            />
                                        </Form.Item>}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            label={<Space size={8} align='start'>
                                                <span className="muli semi-bold">VAT</span>
                                                <Tooltip title='Please enter the VAT % in percentage format. For example, if the VAT is 20%, then enter 20'>
                                                    <Link to=''><FaInfoCircle className="react-icon" /></Link>
                                                </Tooltip>
                                            </Space>}
                                        >
                                            <InputNumber
                                                controls={false}
                                                value={transaction_vat?.other ?? ''}
                                                onChange={handleVatInputChange}
                                                placeholder="Enter VAT"
                                                className="full-percent-width"
                                                formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
                                                parser={(val) => numberFormat(val)}
                                                min={0}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <TransactionAttachmentUpload />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col>
                                <Link to={URLS.TransactionHistory}>
                                    <Button type="primary">
                                        Return
                                    </Button>
                                </Link>
                            </Col>
                            <Col>
                                <Button disabled={!initDataChanged()} onClick={handleSave} type="primary" loading={isSaving}>
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col xxl={12} xl={14} lg={16} md={20} sm={24} xs={24}>
                    <Card hoverable className="b-g hover-no-border">
                        <Space direction="vertical" size={10} className='full-percent-width'>
                            <Typography.Text className="medium fs-18px dark-green m-t-30">Card Balance</Typography.Text>
                            <CardBalanceListing cardId={transaction?.prepaid_card_id} rowType="filled" />
                        </Space>
                    </Card>
                </Col>
            </Row>
        </Spin>
    )
}

export default SpecificCardTransaction;
