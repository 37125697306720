export const ATTACHMENT_EXTENSIONS = [
	'jpg', 'jpeg', 'png', 'pdf', 'docx'
];

export const ATTACHMENT_IMG_EXTENSIONS = [
	'jpg', 'jpeg', 'png',
];

export const NOTIFICATIONS = {
	cardTransaction: 1,
	attachReceipt: 2,
	topUpWallet: 4,
	enhancedTransactions: 5,
	internationalPayment: 6,
	newProduct: 7,
	statementsAndReports: 8,
	workflowApprovals: 9,
	formatPushNotification: 20,
	paymentApproval: 21,
	paymentScheduled: 22,
	scheduledPaymentConfirmation: 23,
	paymentReports: 24
};

export const EEA_EXCLUDED_CCY = [
	'BHD',
	'BGN',
	'CNY',
	'ILS',
	'KWD',
	'OMR',
	'QAR',
	'RUB',
	'THB',
	'UGX',
	'AED',
	'INR',
	'KES',
	'RON',
	'SAR',
	'TRY',
	'MXN',
	'CZK',
	'SGD'
];

export const REFUND_EEA_EXCLUDED_CCY = [
	'BHD',
	'BGN',
	'CNY',
	'ILS',
	'KWD',
	'OMR',
	'QAR',
	'RUB',
	'THB',
	'UGX',
	'AED',
	'INR',
	'KES',
	'RON',
	'SAR',
	'TRY',
	'MXN',
	'CZK',
	'SGD'
];

export const NON_EEA_EXCLUDED_CCY = [
	'BHD',
	'BGN',
	'ILS',
	'KWD',
	'OMR',
	'QAR',
	'RUB',
	'UGX',
	'AED',
	'INR',
	'KES',
	'RON',
	'SAR',
	'TRY',
	'MXN',
	'CZK',
	'SGD'
];

export const REFUND_NON_EEA_EXCLUDED_CCY = [
	'BHD',
	'BGN',
	'CNY',
	'ILS',
	'KWD',
	'OMR',
	'QAR',
	'RUB',
	'UGX',
	'AED',
	'INR',
	'KES',
	'RON',
	'SAR',
	'TRY',
	'MXN',
	'CZK',
	'SGD'
];

export const TCC_CROSS_BORDER_PAYMENT_PURPOSE_CODES = {
	CN_CNY: [
		{ id: 2, code: 'GOD', label: 'Cross-border goods trade' },
		{ id: 3, code: 'STR', label: 'Cross-border service transfer' },
		{ id: 5, code: 'OTF', label: 'Other transfers' },
		{ id: 1, code: 'CTF', label: 'Cross-border capital transfer' },
		{ id: 4, code: 'RMT', label: 'Cross-border individual remittance' },
	],
};

export const PAYMENT_REASONS = [
	{ id: 1, value: 'Payment of Invoice(s)', label: 'Payment of Invoice(s)' },
	{ id: 2, value: 'Internal Payment to Company own account', label: 'Internal Payment to Company own account' },
	{ id: 3, value: 'Payment of Expenses', label: 'Payment of Expenses' },
	{ id: 4, value: 'Payment of Salaries', label: 'Payment of Salaries' },
	{ id: 5, value: 'Payment of Commissions', label: 'Payment of Commissions' },
	{ id: 6, value: 'Disbursement of Dividends', label: 'Disbursement of Dividends' },
	{ id: 7, value: 'Director/Employee loan', label: 'Director/Employee loan' },
	{ id: 8, value: 'Other', label: 'Other (please fill in the Other Reason field below)' },
]

export const PAYMENT_RECIPIENT_TYPES = {
	individual: 'individual',
	company: 'company',
}

export const FUNDING_METHOD = {
	PUSH_FUND: 1,
	EASY_TRANSFER: 2,
}


// On dev/sandbox 
// Codat uses diff paltform_type name than live/UAT
export const CODAT_ACCOUNTING_PLATFORMS = {
	qb: 'QuickBooks Online',
	qb_sandbox: 'QuickBooks Online Sandbox',
	xero: 'Xero',
	msdyn: 'Dynamics 365 Business Central',
}
