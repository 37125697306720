/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Image, Input, Row, Space, Typography, Pagination } from "antd";
import PageDescription from "App/Components/PageDescription";
import CustomTable from "App/Components/CustomTable";
import { FLAGS } from "Constants/Images";
import { useNavigate } from "react-router-dom";
import URLS from "Routes/constants";
import debounce from 'lodash/debounce'
import moment from 'moment';
import { accountingFormat } from "Utils";
import { FORMATS } from "Constants/Formats";
import { useManualTradeHistory } from "./query/useManualTradeHistory";
import { useManualTradeHistoryState } from "./store";
import { useEffect } from "react";
import { useLoginStore } from "App/Pages/Login/store";

function ManualTradeHistory(props) {
    const setState = useManualTradeHistoryState((state) => state.setState);
    const limit = useManualTradeHistoryState(state => state.limit);
    const currentPage = useManualTradeHistoryState(state => state.currentPage);
    const search = useManualTradeHistoryState(state => state.search);
    const { data, isLoading } = useManualTradeHistory(currentPage, limit, search);

    const navigate = useNavigate();
    const config = useLoginStore(s => s.config);

    const paginationStyles = {
        marginTop: '30px',
        marginBottom: '20px',
        padding: '8px 18px',
        background: '#eee',
        borderRadius: '8px'
    };

    useEffect(() => {
        if(config?.manual_payment_status !== 1) {
            navigate(URLS.PaymentHistory)
        }
    }, [config]);


    const columns = [
        {
            title: 'Submission Date',
            dataIndex: 'date_payment',
            key: 'date_payment',
            render: (text, obj) => <Typography.Text className="pointer" onClick={() => goToSpecificPaymentHistory(obj)}>{moment(text, 'YYYY-MM-DD').format(FORMATS.date)}</Typography.Text> 
        },
        {
            title: 'Payment Status',
            dataIndex: ['opt_payment_status', 'option'],
            key: 'opt_payment_status',
            render: (text, obj) => <Typography.Text className="pointer" onClick={() => goToSpecificPaymentHistory(obj)} type={text === 'Paid' || text === 'Completed' ? 'success' : 'danger'}>{text}</Typography.Text>
        },
        {
            title: 'Recipient',
            dataIndex: ['fx_deal_beneficiary', 'beneficiary_account', 'account', 'account_name'],
            key: 'recipient',
            render: (text, obj) => <Typography.Text className="pointer" onClick={() => goToSpecificPaymentHistory(obj)}>{text}</Typography.Text>
        },
        {
            title: 'Amount sent to Volopa',
            dataIndex: 'client_sell_amount',
            key: 'client_sell_amount',
            align: 'left',
            render: (text, obj) => <Space onClick={() => goToSpecificPaymentHistory(obj)} className="pointer m-r-5">
                <div style={{ width: '20px' }}></div>
                {FLAGS[obj.currency_sell.currency] && <Image src={FLAGS[obj.currency_sell.currency]} alt={text.currency} preview={false} width={36} />}
                {accountingFormat(text)}
            </Space>
        },
        {
            title: 'Amount paid to Recipient',
            dataIndex: 'client_buy_amount',
            key: 'client_buy_amount',
            align: 'left',
            render: (text, obj) => <Space className="pointer" onClick={() => goToSpecificPaymentHistory(obj)} >
                <div style={{ width: '20px' }}></div>
                {FLAGS[obj.currency_buy.currency] && <Image src={FLAGS[obj.currency_buy.currency]} alt={obj.currency_buy.currency} preview={false} width={36} />}
                {accountingFormat(text)}
            </Space>
        }
    ];

    const searchInputChanged = (event) => {
        setState({ search: event.target.value });
    };

    const handlePageChange = (page, pageSize) => {
        setState({ currentPage: page })
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    }

    const handlePageSizeChange = (current, size) => {
        setState({
            limit: size,
            page: current
        });
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    }

    const goToSpecificPaymentHistory = (record) => {
        navigate(`${URLS.SpecificManualTradeHistory}/${record?.id}`);
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <PageDescription title='Manual Trade History' text='- Overview of Manual Trade/Payment History' />
                </Col>
            </Row>
            <Row className="m-t-10">
                <Col xxl={4}>
                    <Input.Search placeholder="Search" onChange={debounce(searchInputChanged, 1000)} />
                </Col>
            </Row>
            <Row className="m-t-10">
                <Col span={24}>
                    <CustomTable
                        columns={columns}
                        dataSource={data?.data} loading={isLoading}
                        rowClassName='medium fs-18px'
                        onRow={(record, rowIndex) => ({ onClick: () => goToSpecificPaymentHistory(record) })}
                    />
                    <Pagination
                        style={paginationStyles}
                        onChange={(page, pageSize) => handlePageChange(page, pageSize)}
                        onShowSizeChange={(current, size) => handlePageSizeChange(current, size)}
                        pageSize={limit}
                        total={data?.pager?.count}
                        current={currentPage}
                    />
                </Col>
            </Row>
        </>
    )
}

export default ManualTradeHistory;
