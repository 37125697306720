/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Image, Row, Space, Spin, Typography } from 'antd';
import PageDescription from 'App/Components/PageDescription';
import { FLAGS } from 'Constants/Images';
import CancelFundingConfirmation from './components/CancelFundingConfirmation';
import { useFundingHistoryDetail, useGetFundingDetail } from './store';
import { useCancelFunding } from './query';
import { useNavigate, useParams } from 'react-router-dom';
import CancelFundingReturn from './components/CancelFundingReturn';
import URLS from 'Routes/constants';
import { upperFirst } from 'lodash';
import moment from 'moment';
import { FORMATS } from 'Constants/Formats';
import { accountingFormat } from 'Utils';
import OPT_CW_FUND_STATUS from 'Constants/Transaction';
import { useEffect } from 'react';

function FundingDetails() {
    const { fundingId } = useParams();
    const modalOpen = useFundingHistoryDetail(state => state.modalOpen)
    const modalOpenConfirmed = useFundingHistoryDetail(state => state.modalOpenConfirmed)
    const setState = useFundingHistoryDetail(state => state.setState)
    const cancelFundingApi = useCancelFunding(payload => payload);
    const { data, isLoading } = useGetFundingDetail(fundingId);
    const navigate = useNavigate()

    useEffect(() => {
        setState({
            modalOpen: false,
            modalOpenConfirmed: false
        })
    }, [])
    //
    const handleConfirm = () => {
        setState({ modalOpen: true })
    }

    const handleCancel = () => {
        setState({
            modalOpen: false,
            modalOpenConfirmed: false
        })
    }

    const onFinish = (pass) => {
        cancelFundingApi.mutateAsync({ fundingID: fundingId, password: pass })
    }

    return (
        <>
            <Spin spinning={isLoading}>
                <CancelFundingConfirmation loading={cancelFundingApi.isLoading} onCancel={handleCancel} modalOpen={modalOpen} onFinish={onFinish} />
                <CancelFundingReturn onCancel={handleCancel} modalOpen={modalOpenConfirmed} />
                <Row>
                    <Col span={24}>
                        <PageDescription title='Funding Details' text='- View details for specific funding' />
                    </Col>
                </Row>
                <Row className='m-l-20'>
                    <Col className="m-t-20" xxl={6} xl={8} lg={12} md={16} sm={18} xs={24} >
                        <Row>
                            <Col span={24}>
                                <Typography.Text className='dark-green medium fs-25px'>Funding Details</Typography.Text>
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <Row className='m-l-20 m-t-20'>
                    <Col className="m-t-30" span={24}>
                        <Row>
                            <Col span={24}>
                                <Typography.Text className='dark-green medium fs-18px'>Status</Typography.Text>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={24}>
                                {data && <Typography.Text type={data?.opt_company_wallet_fund_status?.option?.toLowerCase() === 'awaiting funds' ? 'warning' : data?.opt_company_wallet_fund_status?.option?.toLowerCase() === 'cancelled' ? 'danger' : data?.opt_company_wallet_fund_status?.option?.toLowerCase() === 'completed' ? 'success' : ''} className='medium fs-18px'>{upperFirst(data?.opt_company_wallet_fund_status?.option)}</Typography.Text>}
                            </Col>
                        </Row>
                    </Col>
                    <Col className="m-t-30" span={24}>
                        <Row>
                            <Col span={24}>
                                <Typography.Text className='dark-green medium fs-18px'>Amount</Typography.Text>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            {data && <Col span={24}>
                                <Image
                                    src={FLAGS[data?.currency]}
                                    preview={false}
                                    width={30}
                                    className='m-b-5'
                                // alt={curr.currency}
                                />
                                <Typography.Text className='m-t-10 m-l-10 medium bold fs-18px'>
                                    {`${data?.currency} ${accountingFormat(data?.amount)}`}
                                </Typography.Text>
                            </Col>}
                        </Row>
                    </Col>
                    <Col className="m-t-30" span={24}>
                        <Row>
                            <Col span={24}>
                                <Typography.Text className='dark-green medium fs-18px'>Administrator</Typography.Text>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={24}>
                                {data && <Typography.Text className='light-green medium bold fs-18px'>{data?.user?.email}</Typography.Text>}
                            </Col>
                        </Row>
                    </Col>
                    <Col className="m-t-30" span={24}>
                        <Row>
                            <Col span={24}>
                                <Typography.Text className='dark-green medium fs-18px'>Date & Time</Typography.Text>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={24}>
                                {data && <Typography.Text className='medium bold fs-18px'>{`${moment(data?.create_time).format(FORMATS.date)} ${moment(data?.create_time).format(FORMATS.time)}`}</Typography.Text>}
                            </Col>
                        </Row>
                    </Col>
                    <Col className="m-t-30" span={24}>
                        <Row>
                            <Col span={24}>
                                <Typography.Text className='dark-green medium fs-18px'>Description</Typography.Text>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={24}>
                                <Typography.Text className='medium bold fs-18px'>{data?.description}</Typography.Text>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='m-t-30' justify='end'>
                    <Col>
                        <Space size={24}>
                            <Button type='primary' onClick={() => navigate(URLS.FundingHistory)}>Return</Button>
                            <Button disabled={data?.opt_company_wallet_fund_status?.id === OPT_CW_FUND_STATUS.CANCELED || data?.opt_company_wallet_fund_status?.id === OPT_CW_FUND_STATUS.COMPLETED} type='danger' htmlType='submit' onClick={handleConfirm}>Cancel Funding</Button>
                        </Space>
                    </Col>
                </Row>
            </Spin>
        </>
    );
}

export default FundingDetails;