import { Card, Col, Divider, Empty, Row, Spin, Typography } from "antd";
import URLS from "Routes/constants";
import { Link } from 'react-router-dom';
import { useNavbarState } from "App/Components/NavBar/store";
import CardTransaction from "App/Components/NavBar/components/NotificationsOverlay/Components/CardTransaction";
import AttachReceipt from "App/Components/NavBar/components/NotificationsOverlay/Components/AttachReceipt";
import TopUpWallet from "App/Components/NavBar/components/NotificationsOverlay/Components/TopUpWallet";
import InternationalPayment from "App/Components/NavBar/components/NotificationsOverlay/Components/InternationalPayment";
import NewProduct from "App/Components/NavBar/components/NotificationsOverlay/Components/NewProduct";
import StatementsAndReports from "App/Components/NavBar/components/NotificationsOverlay/Components/StatementsAndReports";
import PaymentApproval from "App/Components/NavBar/components/NotificationsOverlay/Components/PaymentApproval";
import ScheduledPaymentConfirmation from "App/Components/NavBar/components/NotificationsOverlay/Components/ScheduledPaymentConfirmation";
import PaymentReports from "App/Components/NavBar/components/NotificationsOverlay/Components/PaymentReports";

function NotificationsOverlay({ notifications, loading }) {
	const setNavbarState = useNavbarState(state => state.setState);
	return (
		<Card
			className='navbar-overlay notifications'
			bodyStyle={{
				maxHeight: 'calc(100vh - 130px)',
				overflowY: 'auto',
				marginTop: '2px',
				marginBottom: '2px',
				borderRadius: '16px'
			}}
			title={
				<Row justify='space-between' align='bottom'>
					<Col>
						<Typography.Text className="fs-24px medium">Notifications</Typography.Text>
					</Col>
					<Col className="m-b-2">
						<Typography.Text><Link className="fs-18px medium" to={URLS.Notifications} onClick={() => setNavbarState({ notificationsOpen: false })} >View All</Link></Typography.Text>
					</Col>
				</Row>
			}
		>
			<Spin spinning={loading}>
				{notifications?.length > 0 ? notifications?.map((val, key) => (
					key < 10 && <div key={key}>
						{key > 0 && <Row justify="center"><Col span={22}><Divider className="m-t-5 m-b-5" /></Col></Row>}
						<Row>
							<Col span={24}>
								{val?.platform_alert_format_id === 1 && <CardTransaction {...val} />}
								{val?.platform_alert_format_id === 2 && <AttachReceipt {...val} />}
								{val?.platform_alert_format_id === 4 && <TopUpWallet {...val} />}
								{val?.platform_alert_format_id === 6 && <InternationalPayment {...val} />}
								{val?.platform_alert_format_id === 7 && <NewProduct {...val} />}
								{val?.platform_alert_format_id === 8 && <StatementsAndReports {...val} />}
								{val?.platform_alert_format_id === 21 && <PaymentApproval {...val} />}
								{val?.platform_alert_format_id === 22 && <ScheduledPaymentConfirmation {...val} />}
								{val?.platform_alert_format_id === 23 && <ScheduledPaymentConfirmation {...val} />}
								{val?.platform_alert_format_id === 24 && <PaymentReports {...val} />}
							</Col>
						</Row>
					</div>
				)) :
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='You have no notifications' />
				}

			</Spin>
		</Card>
	);
}

export default NotificationsOverlay;
