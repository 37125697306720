import create from "zustand";

const usePayMultipleRecipientsState = create(set => ({
    selectedRecipients: undefined,
    frequentRecipients: [],
    recipientsDataGrouped: [],
    paymentPurposesOther: [],
    buttonShow: false,
    backFromSummary: false,
    recipientModalOpen: false,
    recipientSelectedFlag: false,
    setState: obj => set(obj)
}));

export { usePayMultipleRecipientsState };