import create from "zustand";

const useTransactionsExpensesState = create((set, get) => ({
    loading: false,
    page: 1,
    totalPages: 1,
    limit: 10,
    syncQueue: [],
    syncStatus: '1',
    accountOrTaxCodeChangedItems: [],
    syncAllChecked: false,
    isSyncing: false,
    orderByField: null,
    isSyncingDirectCost: false,
    isSyncingBillPayment: false,
    setState: (data) => set(() => data),
    isSyncCheckboxEnabled: (id) => {
        return !!get().accountOrTaxCodeChangedItems.includes(id);
    }
}));

export { useTransactionsExpensesState };