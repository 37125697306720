const URLS = {
  Login: '/login',
  ForgotPassword: '/forgot-password',
  ResetPassword: '/reset-password',
  Error: '/error',
  Notifications: '/notifications',
  Impersonate: '/impersonate',
  SignUp: 'https://dev-business.volopa.com/signup/process.html?step=1.html',
  EmailConfirmation: '/users/email-change/confirm',

  WalletDashboard: '/wallet/dashboard',
  FundWallet: '/wallet/fund',
  ConvertWalletBalances: '/wallet/convert-balances',
  FundingHistory: '/wallet/funding-history',
  FundingDetails: '/wallet/funding-details',
  BankAccounts: '/wallet/bank-accounts',

  PaymentsDashboard: '/payments/dashboard',
  RecipientList: '/payments/recipient-list',
  RecipientDetails: '/payments/recipient-list/recipient-details',
  AddRecipient: '/payments/recipient-list/add-recipient',
  NewPayment: '/payments/new-payment',
  BatchPayments: '/payments/batch-payments',
  PaymentHistory: '/payments/payment-history',
  DraftPayments: '/payments/payment-history/draft-payments',
  SpecificPaymentHistory: '/payments/payment-history/specific-payment-history',
  ManualTradeHistory: '/payments/manual-trade-history',
  SpecificManualTradeHistory: '/payments/manual-trade-history/specific-manual-history',
  PayMultipleRecipients: '/payments/batch-payments/pay-multiple-recipients',
  PaymentSummary: '/payments/batch-payments/pay-multiple-recipients/payment-summary',
  PaymentReports: '/payments/payment-history/payment-reports',

  FxForwardsDashboard: '/fx-forwards/dashboard',

  CardsDashboard: '/cards/dashboard',
  PersonalCardsDashboard: '/cards/personal-dashboard',
  Cards: '/cards/cards',
  AutoFund: '/cards/cards/auto-fund',
  ManageSpecificCard: '/cards/cards/manage-specific-card',
  ConvertCardBalances: '/cards/cards/manage-specific-card/convert-balances',
  NewCardWithdrawal: '/cards/cards/manage-specific-card/new-card-withdrawal',
  NewCardFund: '/cards/cards/manage-specific-card/new-card-fund',
  TransactionHistory: '/cards/transaction-history',
  SpecificCardTransaction: '/cards/transaction-history/specific-card-transaction',
  BulkCardControls: '/cards/bulk-card-controls',
  BulkCardControlsDailyLimits: '/cards/bulk-card-controls/daily-limits',
  BulkCardControlsAutoFund: '/cards/bulk-card-controls/auto-fund',
  BulkCardControlsTxLimits: '/cards/bulk-card-controls/transaction-limits',
  BulkCardControlsPaymentTypes: '/cards/bulk-card-controls/payment-types',
  StatementsAndReports: '/cards/transaction-history/statements-and-reports',
  DownloadAttachment: '/cards/transaction-history/statements-and-reports/download-attachment',

  TermsAndConditions: '/accounting/terms-and-conditions',
  AccountingConnect: '/accounting/connect',
  ExpenseTransactions: '/accounting/expense-transactions',
  BankFeedTransactions: '/accounting/bank-feed-transactions',
  Configuration: '/accounting/configuration',

  CompanyAccount: '/settings/company-account',
  Help: '/settings/company-account/help',
  UserGuides: '/settings/company-account/help/user-guides',
  BankToBankDeliveryTimescales: '/settings/company-account/help/user-guides/bank-to-bank-delivery-timescales',
  VerifyingYourBeneficiaryBank: '/settings/company-account/help/user-guides/verifying-your-beneficiary-bank-details',
  GettingStarted: '/settings/company-account/help/user-guides/getting-started',
  UserPermissions: '/settings/company-account/help/user-guides/user-permissions',
  FAQs: '/settings/company-account/help/faqs',
  VolopaCardFAQs: '/settings/company-account/help/faqs/volopa-card-faqs',
  Terms: '/settings/company-account/help/terms',
  ContactUs: '/settings/company-account/help/contact-us',
  ContactDetails: '/settings/company-account/help/contact-us/contact-details',
  YourApplication: '/settings/company-account/your-application',
  ManageUsers: '/settings/company-account/manage-users',
  ManageSpecificUser: '/settings/company-account/manage-users/manage-specific-user',
  ChangeUserEmail: '/settings/company-account/manage-users/manage-specific-user/change-email',
  RequestCard: '/settings/company-account/manage-users/manage-specific-user/order-new-card',
  RefundDetails: '/settings/company-account/refund-details',
  RefundRecipientDetails: '/settings/company-account/refund-details/recipient',
  UploadUserList: '/settings/company-account/upload-user-list',
  AddNewUser: '/settings/company-account/manage-users/add-new-user',
  UserRolesAndPermissions: '/settings/company-account/manage-user-roles-and-permissions',
  NotificationSettings: '/settings/company-account/notification-settings',
  WorkflowApproval: '/settings/company-account/notification-settings/workflow-approval',
  PersonalProfile: '/settings/personal-profile',
  ChangeEmail: '/settings/personal-profile/change-email',
  EnableTwoFactor: '/settings/personal-profile/enable-two-factor',
  AdditionalServices: '/settings/additional-services',
}

export default URLS;
