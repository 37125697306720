import { Button, Col, Image, notification, Popconfirm, Row, Space, Spin } from 'antd';
import CustomTable from 'App/Components/CustomTable';
import FullScreenModal from 'App/Components/FullScreenModal';
import PageDescription from 'App/Components/PageDescription';
import { useApiRequest } from 'Hooks/API';
import { FLAGS } from 'Constants/Images';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import URLS from 'Routes/constants';
import { useNewPaymentState } from 'App/Pages/Payments/NewPayment/store';
import PaymentHistory from 'App/Pages/Payments/PaymentHistory';
import { useRecipientDetailsState } from 'App/Pages/Payments/RecipientList/RecipientDetails/store';
import { useRecipientDetailsQuery } from 'App/Pages/Payments/RecipientList/RecipientDetails/query';

function RecipientDetails() {
    const setState = useRecipientDetailsState(state => state.setState);
    const historyModalOpen = useRecipientDetailsState(state => state.historyModalOpen);
    const setnewPaymentState = useNewPaymentState(state => state.setState);
    const apiRequest = useApiRequest();
    const navigate = useNavigate();
    const location = useLocation();
    let path = location?.pathname.split('/');
    path.length = path?.length - 1;
    path = path?.join('/');
    const refund = path === URLS.RefundRecipientDetails;
    const { id } = useParams();
    const { data: recipient, isLoading } = useRecipientDetailsQuery(id, refund);
    const recipientData = [
        {
            recipientName: recipient?.name === 'undefined undefined' ? recipient?.beneficiary_company_name : recipient?.name,
            type: recipient?.beneficiary_entity_type?.charAt(0)?.toUpperCase() + recipient?.beneficiary_entity_type?.slice(1),
            firstName: recipient?.beneficiary_first_name || '-',
            lastName: recipient?.beneficiary_last_name || '-',
            address: recipient?.beneficiary_address && recipient?.beneficiary_address,
            city: recipient?.beneficiary_city?.charAt(0)?.toUpperCase() + recipient?.beneficiary_city?.slice(1),
            country: recipient?.beneficiary_country?.charAt(0)?.toUpperCase() + recipient?.beneficiary_country?.slice(1),
            businessNature: recipient?.beneficiary_business_nature,
            businessWebsite: recipient?.beneficiary_company_website,
            key: 0
        }
    ];

    const bankData = [
        {
            bankName: recipient?.bank_name?.charAt(0)?.toUpperCase() + recipient?.bank_name?.slice(1),
            bankCountry: recipient?.bank_country?.charAt(0)?.toUpperCase() + recipient?.bank_country?.slice(1),
            accountHolderName: recipient?.bank_account_holder_name === 'undefined undefined' ? recipient?.beneficiary_company_name : recipient?.bank_account_holder_name,
            currency: recipient?.currency,
            accountNumber: recipient?.account_number !== "" ? recipient?.account_number : 'N/A',
            iban: recipient?.iban !== "" ? recipient?.iban : 'N/A',
            bicSwift: recipient?.bic_swift !== "" ? recipient?.bic_swift : 'N/A',
            key: 0,
            ...recipient
        }
    ];

    const recipientColumns = [
        {
            title: 'Recipient Name',
            dataIndex: 'recipientName',
            key: 'recipientName',
            width: 300
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: 150
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            width: 250
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            width: 200
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: 200
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            width: 200
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            width: 100
        },
        {
            title: 'Business Nature',
            key: 'businessNature',
            dataIndex: 'businessNature',
            render: text => text || '-',
            width: 250
        },
        {
            title: 'Business Website',
            key: 'businessWebsite',
            dataIndex: 'businessWebsite',
            render: text => text || '-'
        }
    ];
    const bankColumns = [
        {
            title: 'Bank Name',
            dataIndex: 'bankName',
            key: 'bankName',
            width: 300
        },
        {
            title: 'Bank Country',
            dataIndex: 'bankCountry',
            key: 'bankCountry',
            width: 150
        },
        {
            title: 'Account Holder Name',
            dataIndex: 'accountHolderName',
            key: 'accountHolderName',
            width: 250
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            width: 200,
            render: (value) => <Space size={4}>
                {FLAGS[value] && (<Image src={FLAGS[value]} width={30} preview={false} />)}
                {value}
            </Space>
        },
        {
            title: 'Account Number',
            dataIndex: 'accountNumber',
            key: 'accountNumber',
            width: 200
        },
        {
            title: 'Routing Code',
            dataIndex: 'routingCode',
            key: 'routingCode',
            width: 200,
            render: (_, obj) => (
                obj?.routing_code_type_1 || obj?.routing_code_type_2 ?
                    <Space direction='vertical' size={0}>
                        {
                            obj?.routing_code_type_1 &&
                            `${obj?.routing_code_type_1 === 'aba' ? 'ABA Code: ' :
                                (obj?.routing_code_type_1 === 'sort_code' ? 'Sort Code: ' :
                                    (obj?.routing_code_type_1 === 'bsb_code' ? 'Bsb Code: ' :
                                        ((obj?.routing_code_type_1 === 'bank_code' ? 'Bank Code: ' :
                                            (`${obj?.routing_code_type_1?.split('_')?.join(' ')}: `)))))}
                    ${obj?.routing_code_value_1}`
                        }
                        {
                            obj?.routing_code_type_2 &&
                            `${obj?.routing_code_type_2 === 'aba' ? 'ABA Code: ' :
                                (obj?.routing_code_type_2 === 'sort_code' ? 'Sort Code: ' :
                                    (obj?.routing_code_type_2 === 'bsb_code' ? 'Bsb Code: ' :
                                        ((obj?.routing_code_type_2 === 'bank_code' ? 'Bank Code: ' :
                                            (`${obj?.routing_code_type_2?.split('_')?.join(' ')}: `)))))}
                    ${obj?.routing_code_value_2}`
                        }
                    </Space> : 'N/A'
            )
        },
        {
            title: 'IBAN',
            dataIndex: 'iban',
            key: 'iban',
            width: 350,
            render: val => val || 'N/A'
        },
        {
            title: 'BIC/SWIFT',
            dataIndex: 'bicSwift',
            key: 'bicSwift'
        }
    ];

    const confirmDelete = async () => {
        let response = await apiRequest('tcc/beneficiary/delete/-' + recipient?.id, 'DELETE');
        if (response && response?.data && response?.data?.success === true) {
            navigate(URLS?.RecipientList);
        } else {
            notification.error({ message: 'Something Went Wrong', description: 'There was an issue deleting this recipient', placement: 'top' });
        }
    }

    const paySpecificRecipient = () => {
        setnewPaymentState({ recipient: recipient, resetRecipient: false });
        navigate(URLS.NewPayment);
    }

    return (
        <Spin size='large' spinning={isLoading}>
            <FullScreenModal onCancel={() => setState({ historyModalOpen: false })} open={historyModalOpen}><PaymentHistory recipientId={recipient?.id} /></FullScreenModal>
            <Row>
                <Col span={24}>
                    <PageDescription title='Recipient Details' text='- View Full Information of Recipient and Bank Details' />
                </Col>
            </Row>
            {!refund && (
                <Row className='m-t-20' justify='end'>
                    <Col>
                        <Button type='primary' onClick={() => setState({ historyModalOpen: true })}>View History</Button>
                    </Col>
                </Row>
            )}
            <Row className='m-t-10'>
                <Col span={24}>
                    <CustomTable columns={recipientColumns} dataSource={recipientData} />
                </Col>
            </Row>
            <Row className='m-t-20'>
                <Col span={24}>
                    <CustomTable columns={bankColumns} dataSource={bankData} />
                </Col>
            </Row>
            {!refund && (
                <Row className='m-t-20' justify='end'>
                    <Col>
                        <Space>
                            <Button type='primary' ghost onClick={() => paySpecificRecipient()}>Pay</Button>
                            <Popconfirm title="Are you sure you want to delete this recipient？" placement="topRight" onConfirm={() => confirmDelete()} okText="Yes" cancelText="No"><Button type='primary' danger>Remove</Button></Popconfirm>
                        </Space>
                    </Col>
                </Row>
            )}
        </Spin>
    )
}

export default RecipientDetails;
